import React, {useState} from 'react'

const SearchForm = (props) => {
    const {formData, errors, touched, getFieldProps} = props
    const [searchTerms, setSearchTerms] = useState(props.searchTerms || '')
    return (
        <React.Fragment><div className="block widget_search side-nav-categories">
            <form onSubmit={formData?.handleSubmit}>
                <div className="input-group">
                    <input type="text" 
                    placeholder="Search for your car here..." 
                    className="input-text"
                    onChange={(event) => setSearchTerms(event?.target?.value)}
                        value={searchTerms}
                        {...getFieldProps('searchField')}
                        name="searchField"
                    />
                    <div className="input-group-append">
                        <button type="submit" className="thm-search"
                        onClick={formData?.handleSubmit}
                        >
                            <i className="fa fa-search"></i>
                        </button>
                    </div>
                </div>
            </form>
        </div>
            {errors && <label className="required">{errors?.searchField || touched?.searchField || ''}</label>}
        </React.Fragment>
    )
}

export default SearchForm
