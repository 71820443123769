import React from 'react'
import Error404 from '../Components/Errors/Error404'

const Error404Page = () => {
    return ( 
        <Error404 />
    )
}

export default Error404Page
