import React from 'react'
import { useCurrentUser } from '../../Contexts/UserContexts'
import Cars from './Components/Cars'
import Profile from './Components/Profile'
import Transactions from './Components/Transactions'

const Dashboard = () => {
  const user = useCurrentUser()
  return (
    <section className="container">
      <div className="row justify-content-center">
        <section className="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-xs-12 ">
          <div className="my-account">
            <div className="dashboard">
              <div className="box-account">
                <div className="page-title mb-3">
                  <h2>Dealer Dashboard, Welcome {user?.username}</h2>
                </div>
                <div className="row justify-content-center">
                  <Profile user={user} />
                  <Cars user={user} />
                  <Transactions user={user} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* <!--row-->  */}
    </section>
  )
}

export default Dashboard
