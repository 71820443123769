import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { searchCars } from '../../Services/api'
import Car from '../Cars/SingleCar/Car'
import Pagination from '../Pagination/Pagination'
import NoResultFound from './Components/NoResultFound'
import Preloader from '../Preloader/Preloader'

const SearchResults = () => {
  const path = useHistory()
  const searchTerm = decodeURI(path.location.search.substring(7))
  const priceRangeQuery = decodeURI(path.location.search.substring(0))
  console.log("QUICK LOG",priceRangeQuery )
  const [searchResults, setSearchResults] = useState([])
  const [loading, setLoading] = useState(false)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [pagination, setPagination] = useState({
    start: 0,
    end: itemsPerPage,
  })

  const onPaginationChange = (start, end) => {
    setPagination({ start, end })
  }

  useEffect(() => {
    const getResults = async () => {
      try {
        setLoading(true)
        // check if the url is from onClick or onSearch using first 8 characters of the url string
        if (priceRangeQuery.substring(0, 7) === '?_where') {
          const priceRangeData = await searchCars(priceRangeQuery)
          setSearchResults(priceRangeData)
        } else if (priceRangeQuery.substring(0, 13) === '?manufacturer') {
          const makeData = await searchCars(priceRangeQuery)
          setSearchResults(makeData)
        } else {
          // fuel type related
          const fuelQuery = `?fuelType_contains=${searchTerm}`
          const fuelData = await searchCars(fuelQuery)

          // body type related
          const bodyQuery = `?bodyType_contains=${searchTerm}`
          const bodyData = await searchCars(bodyQuery)

          // make / manufacturer related
          const manufacturerQuery = `?manufacturer.name_contains=${searchTerm}`
          const manufacturerData = await searchCars(manufacturerQuery)

          // location related
          const locationQuery = `?location_contains=${searchTerm}`
          const locationData = await searchCars(locationQuery)

          // status related
          const statusQuery = `?status_contains=${searchTerm}`
          const statusData = await searchCars(statusQuery)

          // specs related
          const specsQuery = `?specs_contains=${searchTerm}`
          const specsData = await searchCars(specsQuery)

          // check if location data is greater than 0 and set search results to location data
          if (locationData.length > 0) {
            setSearchResults(locationData)
          }
          // else check if status data is greater than 0 and set search results to status data
          else if (statusData.length > 0) {
            setSearchResults(statusData)
          }
          // check if specs data is greater than 0 and set search results to specs data
          else if (specsData.length > 0) {
            setSearchResults(specsData)
          }
          // check if fuel data is greater than 0 and set search results to fuel data
          else if (fuelData.length > 0) {
            setSearchResults(fuelData)
          }
          // else check if body data is greater than 0 and set search results to body data
          else if (bodyData.length > 0) {
            setSearchResults(bodyData)
          }
          // else check if manufacturer data is greater than 0 and set search results to manufacturer data
          else if (manufacturerData.length > 0) {
            setSearchResults(manufacturerData)
          }
          // else make another query on cars and find by transmission
          else {
            const transmissionQuery = `?transmissionType_contains=${searchTerm}`
            const transmissionData = await searchCars(transmissionQuery)

            // check if transmission data is greater than 0 and set search results to transimission data
            if (transmissionData.length > 0) {
              setSearchResults(transmissionData)
            }
            // else make another query on cars and find by color.name
            else {
              const colorQuery = `?color.name_contains=${searchTerm}`
              const colorData = await searchCars(colorQuery)

              // check if color data is greater than 0 and set search results to color data
              if (colorData.length > 0) {
                setSearchResults(colorData)
              }
              // else make another query on cars and find by version.name
              else {
                const versionQuery = `?version.name_contains=${searchTerm}`
                const versionData = await searchCars(versionQuery)

                // check if version data is greater than 0 and set search results to version data
                if (versionData.length > 0) {
                  setSearchResults(versionData)
                }
                // make a query on cars and find by price range
                else {
                  // declare new empty array and set search results to the new empty array
                  const noResultFound = []
                  setSearchResults(noResultFound)
                }
              }
            }
          }
        }
        setLoading(false)
      } catch (error) {
        console.error(error)
      }
    }
    getResults()
  }, [searchTerm])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  })
  return (
    <div className="col-main col-xl-9 col-lg-12 col-md-12 col-sm-12 col-xs-12 product-grid">
      <div className="logo-brand">
        <div className="brand-title">
          <h2 className="text-center">
            {loading ? (
              <i className="fa fa-spinner fa-spin"></i>
            ) : searchResults?.filter((car) => {
                return car?.isAvailable
              })?.length === 0 ? (
              `No result found`
            ) : (
              `${
                searchResults?.filter((car) => {
                  return car?.isAvailable
                })?.length
              } ${
                searchResults?.filter((car) => {
                  return car?.isAvailable
                })?.length === 1
                  ? 'Car matched'
                  : 'Cars matched'
              } ${
                priceRangeQuery.substring(0, 7) ||
                (priceRangeQuery.substring(0, 13) === '?manufacturer') ===
                  '?_where'
                  ? ''
                  : searchTerm
              }`
            )}
          </h2>
        </div>
      </div>
      <div className="pro-coloumn">
        {!loading ? (
          <article className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="category-products">
              <ul className="products-grid">
                {searchResults?.filter((car) => {
                  return car?.isAvailable
                })?.length > 0 ? (
                  <Car
                    cars={searchResults?.filter((car) => {
                      return car?.isAvailable
                    })}
                    loading={loading}
                    pagination={pagination}
                    itemsPerPage={itemsPerPage}
                  />
                ) : (
                  <NoResultFound />
                )}
              </ul>
            </div>
          </article>
        ) : (
          <Preloader />
        )}
        {!loading &&
          searchResults?.filter((car) => {
            return car?.isAvailable
          })?.length > 0 && (
            <Pagination
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              onPaginationChange={onPaginationChange}
              total={
                searchResults?.filter((car) => {
                  return car?.isAvailable
                })?.length
              }
            />
          )}
      </div>
      {/* End Article */}
    </div>
  )
}

export default SearchResults
