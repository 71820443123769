import React from 'react'

const NextButton = ({ next, values, errors, onClick, disabled }) => {
  return (
    <React.Fragment>
      {values?.firstName === '' ||
      values?.lastName === '' ||
      values?.idNumber === '' ||
      values?.contactEmail === '' ||
      values?.phoneNumber === '' ||
      values?.billingAddress === '' ||
      !values?.termsAndConditions ||
      errors?.firstName ||
      errors?.lastName ||
      errors?.idNumber ||
      errors?.contactEmail ||
      errors?.phoneNumber ||
      errors?.billingAddress ||
      errors?.termsAndConditions ? (
        <button
          type="button"
          title="Next"
          // disabled={disabled}
          className="btn continue button-fullwidth"
          onClick={
            values?.firstName === '' ||
            values?.lastName === '' ||
            values?.idNumber === '' ||
            values?.contactEmail === '' ||
            values?.phoneNumber === '' ||
            values?.billingAddress === '' ||
            !values?.termsAndConditions ||
            (!errors?.firstName &&
              !errors?.lastName &&
              !errors?.idNumber &&
              !errors?.contactEmail &&
              !errors?.phoneNumber &&
              !errors?.billingAddress &&
              !errors?.termsAndConditions)
              ? onClick
              : next
          }
        >
          NEXT <i className="fa fa-long-arrow-right"></i>
        </button>
      ) : (
        <button
          type="button"
          title="Next"
          disabled={disabled}
          className="btn continue button-fullwidth"
          onClick={
            values?.firstName !== '' ||
            values?.lastName !== '' ||
            values?.idNumber !== '' ||
            values?.contactEmail !== '' ||
            values?.phoneNumber !== '' ||
            values?.billingAddress !== '' ||
            !values?.termsAndConditions ||
            (!errors?.firstName &&
              !errors?.lastName &&
              !errors?.idNumber &&
              !errors?.contactEmail &&
              !errors?.phoneNumber &&
              !errors?.billingAddress &&
              !errors?.termsAndConditions)
              ? next
              : console.log('LOGGED NOT SUPPOSED TO HAPPEN')
          }
        >
          NEXT <i className="fa fa-long-arrow-right"></i>
        </button>
      )}
    </React.Fragment>
  )
}

export default NextButton
