import React from 'react'
import Preloader from '../../Preloader/Preloader'
import noCars from './Images/cars.jpg'

const NoCars = ({loading}) => {
    return (
        <div className="col-main col-xl-9 col-lg-12 col-md-12 col-sm-12 col-xs-12 product-grid">
            <div className="logo-brand">
                <div className="brand-title">
                <h2 className="text-center">{loading ? 
                <i className="fa fa-spinner fa-spin"></i> : "No Cars Found"}</h2>
                </div>
            </div>
            <br />
            <div className="row justify-content-center">
                {!loading ? <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <div className="card">
                        <img src={noCars} alt="No cars Listed" className="card-img-top mg-responsive-custom"/>
                        <div className="card-header">
                            <h4 className="text-center">No Cars Listed Yet</h4>
                        </div>
                        <div className="card-footer text-center">
                            <p><b><i>Try checking at our nearest dealers...</i></b></p>
                        </div>
                    </div>
                </div>
            : <Preloader />    
            }
            </div>
        </div>
    )
}

export default NoCars
