import React from 'react'
import { NavLink } from 'react-router-dom'
import logo from './Images/logo.png'

const Logo = () => {
  return (
    <NavLink exact to="/" className="navigation-logo">
      <img src={logo} alt="Logo for Cars Emissary" />
    </NavLink>
  )
}

export default Logo
