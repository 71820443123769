import React from 'react'
import Cars from '../Components/Cars/Cars'
import Sidenav from '../Components/Sidenav/Sidenav'

const Home = () => {
    return (
        <section className="page main-container col2-left-layout bounceInUp animated">
            <div className="container">
                <div className="row">
                    <Sidenav />
                    <Cars />
                </div>
            </div>
        </section>
    )
}

export default Home
