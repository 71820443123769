import axios from 'axios'
import { appConfig } from './config'

const createAxios = () => {
  const params = {
    baseURL: appConfig.apiURL,
  }
  return axios.create(params)
}

export const fetchCars = async (query = '') => {
  const url = query ? `/cars?${query}` : `/cars`
  const { data } = await createAxios().get(url)
  return data
}

export const searchCars = async (query = '') => {
  const { data } = await createAxios().get(`/cars${query}`)
  return data
}

export const updateCar = async (referenceNumber) => {
  const { data } = await createAxios().patch(`/cars/${referenceNumber}`)
  return data
}
export const purchaseCar = async (referenceNumber) => {
  const { data } = await createAxios().put(`/cars/${referenceNumber}`)
  return data
}

export const fetchManufacturers = async (query = '') => {
  const { data } = await createAxios().get(
    `/manufacturers/${query}?_sort=name:ASC`,
  )
  return data
}

export const fetchVersions = async (query = '') => {
  const { data } = await createAxios().get(`/versions/${query}?_sort=name:ASC`)
  return data
}

export const fetchPosts = async (query = '') => {
  const { data } = await createAxios().get(`/posts/${query}?_sort=title:ASC`)
  return data
}

export const fetchPostCategories = async (query = '') => {
  const { data } = await createAxios().get(
    `/postcategories/${query}?_sort=title:ASC`,
  )
  return data
}

export const fetchDealers = async () => {
  const { data } = await createAxios().get(`/dealers?_sort=dealerName:ASC`)
  return data
}
export const placeEnquiry = async (enquiry) => {
  const { data } = await createAxios().post('/enquiries', enquiry)
  return data
}

export const placeReservation = async (reservation) => {
  const { data } = await createAxios().post('/reservations', reservation)
  return data
}
export const fetchReservation = async (reservationKey) => {
  const url = reservationKey
    ? `/reservations/${reservationKey}`
    : '/reservations?_sort=reservationKey:ASC'
  const { data } = await createAxios().get(url)
  return data
}

export const patchReservation = async (reservationKey) => {
  const { data } = await createAxios().patch(`/reservations/${reservationKey}`)
  return data
}

export const testApi = async (params) => {
  const { data } = await createAxios().post('/test-apis', params)
  return data
}
