import React, { useContext } from 'react'
import { useHistory } from 'react-router'
import { CarContext } from '../../../Contexts/CarContext'

const AddCar = ({ product }) => {
  const { addCar, car } = useContext(CarContext)
  const { items = [] } = car
  const history = useHistory()
  return (
    <div className="add_cart">
      <button
        className="btn btn-block"
        type="submit"
        onClick={() => {
          if (items.length >= 0) {
            addCar(product)
            history.push(
              `/checkout/${items[0]?.referenceNumber?.toUpperCase()}`,
            )
          }
        }}
      >
        {product?.reservations?.length > 0 && product?.isReserved ? (
          <b>{product?.reservations?.length} QUEUING</b>
        ) : (
          <b>BUY NOW </b>
        )}{' '}
        <i
          className={
            product?.reservations?.length > 0 ? 'fa fa-list' : 'fa fa-money'
          }
        />
      </button>
    </div>
  )
}

export default AddCar
