import React from 'react'
import { useHistory } from 'react-router'
import noResultFound from './Images/noResultFound.jpg'

const NoResultFound = () => {
  const history = useHistory()
  return (
    <div className="row justify-content-center">
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div className="">
          <img
            src={noResultFound}
            className="card-img-top"
            alt="No results found"
          />
          <div className="card-body">
            <button
              className="btn button-fullwidth"
              onClick={() => history.push('/')}
            >
              BACK TO CARS <i className="fa fa-car"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NoResultFound
