import React from 'react'
import CarDetails from '../Components/Cars/CarDetails/CarDetails'
import Sidenav from '../Components/Sidenav/Sidenav'

const CarDetail = () => {
    return (
        <section className="main-container col2-left-layout bounceInUp animated">
            <div className="container">
                <div className="row">
                    <Sidenav/>
                    <CarDetails />
                </div>
            </div>
        </section>
    )
}

export default CarDetail
