import React from 'react'
import mastercard from '../Images/mastercard.png'
import paypal from '../Images/paypal.png'
import visa from '../Images/visa.png'

const FooterCredits = () => {
  const year = new Date()
  const since = 2021
  return (
    <div className="footer-top">
      <div className="container">
        <div className="row">
          <div className="col-sm-4 col-xs-12 coppyright">
            Developed with <i className="fa fa-heart"></i> by{' '}
            <a>Kudzai Machiridza</a>
          </div>
          <div className="col-sm-4 col-xs-12 coppyright">
            &copy;{' '}
            {year.getFullYear() === since
              ? since + ' '
              : `${since} - ${year.getFullYear()}` + ' '}
            Cars Emissary | All Rights Reserved.
          </div>
          <div className="col-xs-12 col-sm-4">
            <div className="payment-accept">
              <img src={mastercard} alt="Master Card Payment" />
              <img src={paypal} alt="Pay Pal Payment" />
              <img src={visa} alt="Visa Card Payment" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FooterCredits
