import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { NotificationManager } from 'react-notifications'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { CarContext } from '../Contexts/CarContext'
import Enquire from '../Components/Enquire/Enquire'
import { placeEnquiry } from '../Services/api'

const EnquirePage = () => {
  const idNumberRegex = /(?=.*[a-z])/
  const history = useHistory()
  const { car } = useContext(CarContext)
  const { items = [] } = car
  const formData = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      idNumber: '',
      contactEmail: '',
      phoneNumber: '',
      billingAddress: '',
      termsAndConditions: false,
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string()
        .required('First name is required.')
        .min(2, 'First name is too short.')
        .max(30, 'First name is too long.'),
      lastName: Yup.string()
        .required('Last name is required.')
        .min(4, 'Last name is too short.')
        .max(50, 'Last name is too long.'),
      idNumber: Yup.string()
        .required('ID Number is required.')
        .min(15, 'Should be 15 characters long including the - symbol.')
        .max(
          15,
          'Should not be greater than 15 characters including the - symbol.',
        ),
      contactEmail: Yup.string()
        .required('Please provide your email address.')
        .email('Please provide a valid email address.')
        .min(6, 'Email address is too short.')
        .max(50, 'Email address is too long.'),
      phoneNumber: Yup.number()
        .required('Phone number is required.')
        .min(242000000, 'Phone number not valid.')
        .max(789999999, 'Phone number not valid.'),
      billingAddress: Yup.string()
        .required('Business / Residential address required.')
        .min(6, 'Address is too short')
        .max(100, 'Address is too long.'),
      termsAndConditions: Yup.bool().oneOf([true], 'Please agree to continue.'),
    }),
    onSubmit: async (values) => {
      try {
        const tomorrow = new Date(
          new Date().getTime() + 24 * 60 * 60 * 1000,
        ).getTime()

        const formatDate = new Date(tomorrow)
          .toString()
          .replace('GMT+0200 (Central Africa Time)', ' ')
        const enquiryDetails = await placeEnquiry({
          ...values,
          isResponded: false,
          car: items[0],
          timeOfEnquiry: `${formatDate?.substring(0, 21)}`,
        })
        if (enquiryDetails) {
          history.push(`/`)
          NotificationManager.success('Enquiry Sent', 'Success', 3000)
        }
      } catch (error) {
        console.error(error)
        history.push(`/enquire/${items[0]?.referenceNumber}`)
        NotificationManager.error('Failed to complete enquiry', 'Error', 3000)
      }
    },
  })
  const { errors, values, touched, getFieldProps } = formData
  return (
    <Enquire
      errors={errors}
      values={values}
      touched={touched}
      getFieldProps={getFieldProps}
      formData={formData}
    />
  )
}

export default EnquirePage
