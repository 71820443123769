import React from 'react'
import './CSS/style.css'

const StepHeader = ({ size, title, current }) => {
  return (
    <div className="step-title">
      <div className="row justify-content-center">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <h3 className="one_page_heading"> {title}</h3>
        </div>
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
          {size > 3 ? (
            <ul id="progressbar">
              <li className={current !== 1 ? '' : 'active'} id="personal"></li>
              <li className={current !== 2 ? '' : 'active'} id="payment"></li>
              <li className={current !== 3 ? '' : 'active'} id="account"></li>
              <li className={current !== 4 ? '' : 'active'} id="confirm"></li>
            </ul>
          ) : (
            <ul id="progressbar">
              <li className={current !== 1 ? '' : 'active'} id="personal"></li>
              <li className={current !== 2 ? '' : 'active'} id="confirm"></li>
            </ul>
          )}
        </div>
      </div>
    </div>
  )
}

export default StepHeader
