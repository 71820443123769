import React from 'react'

const Cars = () => {
  return (
    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-3">
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">
            Cars <i className="fa fa-car"></i>
          </h5>
          <p className="card-text">
            Upload and view cars in one place. Manage your cars from this panel.
          </p>
          <button type="submit" className="btn button-fullwidth">
            GO TO CARS
          </button>
        </div>
      </div>
    </div>
  )
}

export default Cars
