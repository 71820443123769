import React from 'react'
import { NavLink } from 'react-router-dom'

const Login = ({ formData, getFieldProps, errors, touched, values }) => {
  return (
    <section className="col-main col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div className="my-account">
        <form onSubmit={formData?.handleSubmit}>
          <h4 className="text-center">LOGIN</h4>
          <div className="form-group">
            {errors?.identifier && touched?.identifier ? (
              <label className="required">{errors?.identifier}</label>
            ) : (
              <label>
                Email address{' '}
                {!errors?.identifier && values?.identifier === '' ? (
                  <span className="required"> *</span>
                ) : (
                  ''
                )}
              </label>
            )}
            <input
              type="email"
              className="form-control"
              name="identifier"
              placeholder="e.g dealer@carsemissary.co.zw"
              {...getFieldProps('identifier')}
            />
            {/* <small id="emailHelp" className="form-text text-muted">
              We'll never share your email with anyone else.
            </small> */}
          </div>
          <div className="form-group mb-4">
            {errors?.password && touched?.password ? (
              <label className="required">{errors?.password}</label>
            ) : (
              <label>
                Password
                {!errors?.password && values?.password === '' ? (
                  <span className="required"> *</span>
                ) : (
                  ''
                )}
              </label>
            )}
            <input
              type="password"
              className="form-control"
              placeholder="e.g Qw3rtyu!0p"
              name="password"
              {...getFieldProps('password')}
            />
          </div>
          <button
            type="submit"
            className="btn mt-1 button-fullwidth btn-primary"
            onClick={formData?.handleSubmit}
          >
            {!formData?.isSubmitting && 'LOGIN '}
            {!formData?.isSubmitting ? (
              <i className="fa fa-lock"></i>
            ) : (
              <i className="fa fa-spinner fa-spin"></i>
            )}
          </button>
        </form>
        <br />
        <br />
        <p className="text-center mb-0">
          <b>No Account ? </b> <br /> <br />{' '}
          <b>
            <NavLink to="/register">Register Here</NavLink>
          </b>
        </p>
      </div>
    </section>
  )
}

export default Login
