import React, { useEffect, useState } from 'react'
import Pagination from '../../Pagination/Pagination'
import Car from '../SingleCar/Car'

const CarsContainer = ({ cars, loading }) => {
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [pagination, setPagination] = useState({
    start: 0,
    end: itemsPerPage,
  })

  const onPaginationChange = (start, end) => {
    setPagination({ start, end })
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  })

  return (
    <div className="col-main col-xl-9 col-lg-12 col-md-12 col-sm-12 col-xs-12 product-grid">
      <div className="logo-brand">
        <div className="brand-title">
          <h2 className="text-center">
            {loading ? (
              <i className="fa fa-spinner fa-spin"></i>
            ) : (
              'Recently Listed'
            )}
          </h2>
        </div>
      </div>
      <div className="pro-coloumn">
        <article className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div className="category-products">
            <ul className="products-grid card-deck">
              <Car
                cars={cars?.filter((car) => {
                  return car?.isAvailable
                })}
                loading={loading}
                pagination={pagination}
                itemsPerPage={itemsPerPage}
              />
            </ul>
          </div>
        </article>
        {!loading &&
          cars?.filter((car) => {
            return car?.isAvailable
          })?.length > 0 && (
            <Pagination
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              onPaginationChange={onPaginationChange}
              total={
                cars?.filter((car) => {
                  return car?.isAvailable
                })?.length
              }
            />
          )}
      </div>
      {/* End article   */}
    </div>
  )
}

export default CarsContainer
