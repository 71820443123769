import React from 'react'
import { NotificationManager } from 'react-notifications'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Sidenav from '../Components/Sidenav/Sidenav'
import TrackingForm from '../Components/Track/TrackingForm'
import { fetchReservation } from '../Services/api'

const TrackingPage = () => {
  const history = useHistory()
  const formData = useFormik({
    initialValues: {
      // contactEmail: '',
      reservationKey: '',
    },
    validationSchema: Yup.object().shape({
      // contactEmail: Yup.string()
      //   .required('Please provide your email address.')
      //   .email('Please provide a valid email address.')
      //   .min(6, 'Email address is too short.')
      //   .max(50, 'Email address is too long.'),
      reservationKey: Yup.string()
        .required('Provide  your Reservation ID.')
        .min(9, 'Reservation ID is too short.')
        .max(9, 'Reservation ID is too long.'),
      // FGKUY3243
    }),
    onSubmit: async (values) => {
      try {
        const localReservationKey = localStorage.getItem('reservationKey')
        const data = await fetchReservation()
        const reservation = data.filter((item) => {
          return item?.reservationKey === values?.reservationKey
        })
        if (
          reservation?.length > 0 &&
          reservation[0]?.reservationKey === localReservationKey
        ) {
          history.push(`/reservations/${values?.reservationKey}`)
          NotificationManager.success(
            'Found Matching Reservation',
            'SUCCESS',
            3000,
          )
        } else {
          history.push(`/track`)
          NotificationManager.warning('No match Found', 'Oops', 3000)
        }
      } catch (error) {
        console.error(error)
        history.push(`/`)
        NotificationManager.error('An error occured', 'ERROR', 3000)
      }
    },
  })
  const { getFieldProps, errors, touched, values } = formData
  return (
    <section className="main-container col2-left-layout bounceInUp animated">
      <div className="container">
        <div className="row justify-content-center">
          <Sidenav />
          <TrackingForm
            getFieldProps={getFieldProps}
            errors={errors}
            touched={touched}
            formData={formData}
            values={values}
          />
        </div>
      </div>
    </section>
  )
}

export default TrackingPage
