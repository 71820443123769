import React from 'react'
import { Redirect, Route } from 'react-router-dom'

const PrivateRoute = ({
  path,
  loading,
  user,
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={() =>
        user?.isAuthenticated ? <Component /> : <Redirect to="/login" />
      }
    />
  )
}

export default PrivateRoute
