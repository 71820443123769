import React, { useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { NavLink } from 'react-router-dom'

const PriceCategories = ({ categoryPurpose, list, loading }) => {
  const [open, setOpen] = useState(false)
  return (
    <div>
      {/* <!-- BEGIN SIDE-NAV-CATEGORY --> */}
      <div className="side-nav-categories">
        <div className={!loading ? "block-title" : "block-title text-center"}
          onClick={() => setOpen(!open)}
          style={{ cursor: 'pointer' }}
        >
          {!loading ? <>{" "} <i className="fa fa-bars"></i>{" " + categoryPurpose}</> : <i className="fa fa-spinner fa-spin foat-right"></i> }
        </div>
        {/* <!--block-title-->  */}
        {/* <!-- BEGIN BOX-CATEGORY --> */}
        {open && !loading && <div className="box-content box-category">
            <AnimatePresence>
                <ul>
                    {list.length > 0 && list.map((item, i) =>
                      <motion.li
                        variants={{
                          hidden: {
                            opacity: 0,
                            y: -50
                          },
                          visible: (i) => ({
                            opacity: 1,
                            y: 0,
                            transition: {
                              delay: i * 0.1
                            },
                          }),
                        }}
                        initial="hidden"
                        animate="visible"
                        custom={i}
                        key={item?.id}>
                        <NavLink to={"/results" + item?.url} 
                        onClick={() => setOpen(!open)}
                        style={{cursor: 'pointer'}}
                        >
                          {" "}
                          <span className="text-center">
                            {" " + item?.range}
                          </span>
                        </NavLink>
                      </motion.li>
                    )}
                </ul>
            </AnimatePresence>
        </div>}
        {/* <!--box-content box-category-->  */}
      </div>
      {/* <!--side-nav-categories--> */}
    </div>
  )
}

export default PriceCategories
