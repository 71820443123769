import React, { useEffect } from 'react'
import StepHeader from './StepHeader'
import Message from './Cards/Message'
import PrevButton from '../../Buttons/Steps/PrevButton'
import CheckoutButton from '../../Buttons/Checkout/CheckoutButton'

const FinalStep = (props) => {
  const { formData } = props
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  })

  const messageTitle = 'BEFORE YOU PROCEED, PLEASE NOTE'
  const messageBody =
    'Failure to make a payment will result in restrictions to reserve a car in the future. Only proceed if you are sure of making a payment within this 24 hour period.'
  return (
    <li id="opc-billing" className="section allow active">
      <StepHeader
        title="CONFIRM YOUR RESERVATION"
        current={props.current}
        size={props.size}
        jump={props.jump}
      />
      <div id="checkout-step-billing" className="step a-item">
        <div id="accordion">
          <Message
            cardType="danger"
            title={messageTitle}
            message={messageBody}
          />
          <br />
          {/* Buttons */}
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-3">
              <PrevButton prev={props.prev} />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-3">
              <CheckoutButton
                formData={formData}
                disabled={formData.isSubmitting}
                message="Reserve Your Car Now"
              />
            </div>
          </div>
        </div>
      </div>
    </li>
  )
}

export default FinalStep
