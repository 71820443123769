import React from 'react'

const Message = ({cardType, title, message}) => {
    return (
        <div className="row justify-content-center">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className={`"card black-white bg-${cardType} mb-10"`}>
                        <div className="card-body">
                            <h5 className="card-title">{title}</h5>
                            <p className="card-text">
                            {message}
                            </p>
                        </div>
                    </div>
        </div>
    </div>
    )
}

export default Message
