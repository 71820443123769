import React from 'react'

const TableHeader = ({ reservation, loading }) => {
  const capitalizeFirstLetter = (string) => {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1)
  }
  return (
    <div className="welcome-msg">
      <p>
        {!loading && (
          <strong>
            Hello,{' '}
            {`${capitalizeFirstLetter(
              reservation?.firstName,
            )} ${capitalizeFirstLetter(reservation?.lastName)}`}
            .
          </strong>
        )}
      </p>
      {!loading && (
        <>
          {!reservation?.isPaid ? (
            <p className="m-0 mb-1">
              From this page you have the ability to view a snapshot of your
              recent reservation details and proceed to payment at any time,
              after one of our agents gets in touch with you via phone to
              process further your reservation.
            </p>
          ) : (
            <p className="m-0 mb-1">
              Your payment has been succesful, please check
              <b>{' ' + reservation?.contactEmail + ' '}</b> for an email with
              payment details whilst you await payment confirmation.
            </p>
          )}
          <b>
            NB: Remember your Reservation ID{' '}
            <i>{reservation?.reservationKey + ' '}</i>, for tracking purposes.
          </b>
        </>
      )}
    </div>
  )
}

export default TableHeader
