import React from 'react'

const Success = () => {
  return (
    <div className="col-main col-xl-9 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div className="row justify-content-center mt-15">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-8 col-xs-12">
          <div className="img-responsive-custom-1 text-center">
            <img
              className="card-img"
              src="https://www.kindpng.com/picc/m/106-1064902_transparent-check-mark-gif-png-download-done-icon.png"
              alt="Successful payment of car"
            />
            <div className="card-body">
              <h4>Transaction Completed.</h4>
              <p>
                Please wait while we verify and confirm your payment before we
                get back to you.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Success
