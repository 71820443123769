import React, { useEffect, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import { NotificationContainer } from 'react-notifications'
import Footer from './Components/Footer/Footer'
import Home from './Pages/Home'
import Results from './Pages/Results'
import Error404Page from './Pages/Error404Page'
import DashboardPage from './Pages/DashboardPage'
import OrdersPage from './Pages/OrdersPage'
import CheckoutPage from './Pages/CheckoutPage'
import CarDetail from './Pages/CarDetail'
import ScrollToTop from './Hooks/ScrollToTop'
import EnquirePage from './Pages/EnquirePage'
import Header from './Components/Header/Header'
import TrackingPage from './Pages/TrackingPage'
import SuccessPage from './Pages/SuccessPage'
import RestrictedPage from './Pages/RestrictedPage'
import LoginPage from './Pages/LoginPage'
import RegisterPage from './Pages/RegisterPage'
import PrivateRoute from './Components/Auth/PrivateRoute'
import ProtectedLogin from './Components/Auth/ProtectedLogin'
import { useCurrentUser } from './Contexts/UserContexts'

const App = () => {
  const user = useCurrentUser()
  return (
    <div id="page">
      <ScrollToTop>
        <Header />
        <Routes user={user} />
        <Footer />
      </ScrollToTop>
      <NotificationContainer />
    </div>
  )
}

const Routes = ({ user }) => {
  return (
    <Switch>
      <PrivateRoute
        user={user}
        exact
        path="/dashboard"
        component={DashboardPage}
      />
      <ProtectedLogin exact path="/login" user={user} component={LoginPage} />
      <Route exact path="/checkout/:referenceNumber" component={CheckoutPage} />
      <Route exact path="/enquire/:referenceNumber" component={EnquirePage} />
      <Route exact path="/register" component={RegisterPage} />
      <Route exact path="/" component={Home} />
      <Route exact path="/cars/:referenceNumber" component={CarDetail} />
      <Route
        exact
        path="/reservations/:reservationKey"
        component={OrdersPage}
      />
      <Route exact path="/track" component={TrackingPage} />
      <Route exact path="/results" component={Results} />
      <Route exact path="/success" component={SuccessPage} />
      <Route exact path="/restricted" component={RestrictedPage} />
      <Route exact path="*" component={Error404Page} />
    </Switch>
  )
}

export default App
