import React from 'react'

const Transactions = () => {
  return (
    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-3">
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">
            Transactions <i className="fa fa-money"></i>
          </h5>
          <p className="card-text">
            Safely access your cars' financial statements from this panel.
          </p>
          <button type="submit" className="btn button-fullwidth">
            GO TO TRANSACTIONS
          </button>
        </div>
      </div>
    </div>
  )
}

export default Transactions
