import React from 'react'
import { NavLink } from 'react-router-dom'

const Login = ({ formData, getFieldProps, errors, touched, values }) => {
  return (
    <section className="col-main col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div className="my-account">
        <form onSubmit={formData?.handleSubmit}>
          <h4 className="text-center">REGISTER TODAY</h4>
          <div className="form-group">
            {errors?.username && touched?.username ? (
              <label className="required">{errors?.username}</label>
            ) : (
              <label>
                Username{' '}
                {!errors?.username && values?.username === '' ? (
                  <span className="required"> *</span>
                ) : (
                  ''
                )}
              </label>
            )}
            <input
              type="email"
              className="form-control"
              name="username"
              placeholder="e.g Moyondizvo Cars"
              {...getFieldProps('username')}
            />
          </div>
          <div className="form-group">
            {errors?.email && touched?.email ? (
              <label className="required">{errors?.email}</label>
            ) : (
              <label>
                Email address{' '}
                {!errors?.email && values?.email === '' ? (
                  <span className="required"> *</span>
                ) : (
                  ''
                )}
              </label>
            )}
            <input
              type="email"
              className="form-control"
              name="email"
              placeholder="e.g dealer@carsemissary.co.zw"
              {...getFieldProps('email')}
            />
          </div>
          <div className="form-group mb-4">
            {errors?.password && touched?.password ? (
              <label className="required">{errors?.password}</label>
            ) : (
              <label>
                Password
                {!errors?.password && values?.password === '' ? (
                  <span className="required"> *</span>
                ) : (
                  ''
                )}
              </label>
            )}
            <input
              type="password"
              className="form-control"
              placeholder="e.g Qw3rtyu!0p"
              name="password"
              {...getFieldProps('password')}
            />
          </div>
          <div className="form-group mb-4">
            {errors?.confirmPassword && touched?.confirmPassword ? (
              <label className="required">{errors?.confirmPassword}</label>
            ) : (
              <label>
                Confirm Password
                {!errors?.confirmPassword && values?.confirmPassword === '' ? (
                  <span className="required"> *</span>
                ) : (
                  ''
                )}
              </label>
            )}
            <input
              type="password"
              className="form-control"
              placeholder="e.g Qw3rtyu!0p"
              name="confirmPassword"
              {...getFieldProps('confirmPassword')}
            />
          </div>
          <button
            type="submit"
            className="btn mt-1 button-fullwidth btn-primary"
            onClick={formData?.handleSubmit}
          >
            {!formData?.isSubmitting && 'REGISTER '}
            {!formData?.isSubmitting ? (
              <i className="fa fa-check"></i>
            ) : (
              <i className="fa fa-spinner fa-spin"></i>
            )}
          </button>
        </form>
        <br />
        <br />
        <p className="text-center mb-0">
          <b>Already Registered ?</b> <br /> <br />{' '}
          <b>
            <NavLink to="/login">Login Here</NavLink>
          </b>
        </p>
      </div>
    </section>
  )
}

export default Login
