import React from 'react'

const AboutCar = ({ details }) => {
    const capitalizeFirstLetter = (string) => {
        return string?.charAt(0)?.toUpperCase() + string?.slice(1);
    }
    return (
        <React.Fragment>
            <div className="product-name">
                {details?.specs ? 
                <h1>
                    {`${capitalizeFirstLetter(details?.manufacturer?.name)} ${capitalizeFirstLetter(details?.specs)}`}
                </h1>
                :
                <h1>
                    {`${capitalizeFirstLetter(details?.manufacturer?.name)} ${capitalizeFirstLetter(details?.version?.name)}`}
                </h1>                    
            }
            </div>
            <div className="price-block">
                <div className="price-box">
                    <span className="regular-price price">
                        <span className="regular-price">
                            {details?.price ? <span className="price">
                                US$ {details?.price?.toFixed(2)}
                            </span>
                                : <b>Enquire Price</b>}
                        </span>
                    </span>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AboutCar
