import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Thumbs, Pagination } from 'swiper/core'

import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/pagination/pagination.min.css'
import 'swiper/components/thumbs/thumbs.min.css'

import './Styles/styles.css'

SwiperCore.use([Navigation, Thumbs, Pagination])
const CarImages = ({ photos }) => {
  // destructure photos from product object
  // check the mime type of the image before submitting to
  // the  photos.push({
  //     original: `${photo.url}`,
  //         thumbnail: `${photo.url}`
  // }) object.
  // screen the images accordingly image/jpeg goes to the gallery object while video/mp4
  //  goes the other way....
  //
  //
  let imgArr = []
  let videoArr = []
  let mime = photos?.map((item) => {
    item.mime === 'image/jpeg' ? imgArr.push(item) : videoArr.push(item)
  })

  const [loading, setLoading] = useState(false)
  const [pictures, setPictures] = useState([])
  const [thumbsSwiper, setThumbsSwiper] = useState(null)
  useEffect(() => {
    try {
      if (imgArr && imgArr.length > 0) {
        setLoading(true)
        let photos = []
        imgArr &&
          imgArr.map((photo) => {
            return photos.push({
              original: `${photo.url}`,
              thumbnail: `${photo.url}`,
            })
          })
        setPictures(photos)
        setLoading(false)
      }
    } catch (error) {
      console.error(error)
    }
  }, [photos])
  return (
    <>
      <Swiper
        spaceBetween={10}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper }}
        navigation={true}
        className="mySwiper2"
        style={{
          '--swiper-navigation-color': '#14BAFF',
          '--swiper-pagination-color': '#14BAFF',
        }}
        pagination={{
          type: 'progressbar',
        }}
      >
        {pictures.map((picture) => {
          return (
            <SwiperSlide key={picture?.original}>
              <img src={picture?.original} />
            </SwiperSlide>
          )
        })}
      </Swiper>
      <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={10}
        slidesPerView={4}
        watchSlidesVisibility={true}
        watchSlidesProgress={true}
        className="mySwiper"
      >
        {pictures.length > 0 &&
          pictures.map((picture) => {
            return (
              <SwiperSlide key={picture?.original}>
                <img src={picture?.original} />
              </SwiperSlide>
            )
          })}
      </Swiper>
    </>
  )
}

export default CarImages
