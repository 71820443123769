import React from 'react'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { NotificationManager } from 'react-notifications'
import Login from '../Components/Auth/Login'
import { auth } from '../Services/auth'
import { useDispatchCurrentUser } from '../Contexts/UserContexts'

const LoginPage = () => {
  const history = useHistory()
  const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
  const dispatch = useDispatchCurrentUser()
  const formData = useFormik({
    initialValues: {
      identifier: '',
      password: '',
    },
    validationSchema: Yup.object().shape({
      identifier: Yup.string()
        .email('Please provide a valid email.')
        .required('Username or email cannot be empty.')
        .min(6, 'That is too short.'),
      password: Yup.string()
        .matches(
          passwordRegex,
          'Must contain at least 1 uppercase, lowercase, special character and number.',
        )
        .required('Password cannot be empty.'),
    }),
    onSubmit: async (values) => {
      try {
        const res = await auth('/auth/local', 'POST', { ...values })
        if (res?.status === 'Authenticated') {
          dispatch({ type: 'LOGIN', user: res?.user })
          history.push('/dashboard')
          NotificationManager.success(
            'Successfully logged in.',
            'Success',
            3000,
          )
        } else {
          history.push('/login')
          NotificationManager.warning('Invalid Credentials', 'Oops', 3000)
        }
      } catch (error) {
        console.error(error)
        NotificationManager.error('An error occured.', 'Error', 3000)
      }
    },
  })
  const { errors, touched, getFieldProps, values } = formData
  return (
    <section className="container">
      <div className="row justify-content-center">
        <Login
          getFieldProps={getFieldProps}
          formData={formData}
          errors={errors}
          touched={touched}
          values={values}
        />
      </div>
    </section>
  )
}

export default LoginPage
