import React from 'react'

const TrackingForm = ({ getFieldProps, errors, touched, formData, values }) => {
  return (
    <section className="col-main col-xl-9 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div className="my-account">
        <form onSubmit={formData.handleSubmit}>
          <h4 className="text-center">Track Your Reservation Here</h4>
          <div className="form-group">
            {errors?.reservationKey && touched?.reservationKey ? (
              <label htmlFor="reservationKeyError">
                <span className="required">{errors?.reservationKey}</span>
              </label>
            ) : (
              <label htmlFor="reservationKey">
                Reservation ID
                {errors.reservationKey || values.reservationKey === '' ? (
                  <span className="required">*</span>
                ) : (
                  ''
                )}
              </label>
            )}
            <input
              type="text"
              className="form-control"
              placeholder="FGKUY3243"
              name="reservationKey"
              {...getFieldProps('reservationKey')}
            />
            {values?.reservationKey === '' || errors?.reservationKey ? (
              <i className="form-text text-muted">
                ID was given to you when you made your reservation earlier.
              </i>
            ) : (
              ''
            )}
          </div>
          <button
            type="submit"
            className="btn button-fullwidth"
            onClick={() => formData.handleSubmit}
          >
            {!formData?.isSubmitting && 'FIND MY RESERVATION' + ' '}
            <i
              className={
                formData?.isSubmitting
                  ? 'fa fa-spinner fa-spin'
                  : 'fa fa-angle-double-right'
              }
            ></i>
          </button>
        </form>
      </div>
    </section>
  )
}

export default TrackingForm
