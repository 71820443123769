import React, { useContext } from 'react'
import { useHistory } from 'react-router'
import { CarContext } from '../../../Contexts/CarContext'

const EnquireCar = ({ product }) => {
  const { addCar, car } = useContext(CarContext)
  const { items = [] } = car
  const history = useHistory()
  return (
    <div className="add_cart">
      <button
        className="btn btn-block"
        type="submit"
        onClick={() => {
          if (items.length >= 0) {
            addCar(product)
            history.push(`/enquire/${items[0].id.toUpperCase()}`)
          }
        }}
      >
        {product?.enquiries?.length === 0 ? (
          <b>ENQUIRE NOW</b>
        ) : (
          <b>RECENTLY ENQUIRED</b>
        )}{' '}
        <i
          className={
            product?.enquiries?.length === 0
              ? 'fa fa-question'
              : 'fa fa-stack-exchange'
          }
        />
      </button>
    </div>
  )
}

export default EnquireCar
