import React, { useEffect } from 'react'
import NextButton from '../../Buttons/Steps/NextButton'
import StepHeader from './StepHeader'

const EnquireForm = (props) => {
  const { errors, touched, formData, getFieldProps, values } = props
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])
  return (
    <li id="opc-billing" className="section allow active">
      <StepHeader
        title="Personal Information"
        current={props.current}
        size={props.size}
        jump={props.jump}
      />
      <div id="checkout-step-billing" className="step a-item">
        <form>
          <div className="form-row">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-3">
              {errors?.firstName && touched?.firstName ? (
                <label htmlFor="firstNameError">
                  <span className="required">{errors?.firstName}</span>
                </label>
              ) : (
                <label htmlFor="firstName">
                  First Name{' '}
                  {errors.firstName || values.firstName === '' ? (
                    <span className="required">*</span>
                  ) : (
                    ''
                  )}
                </label>
              )}

              <input
                type="text"
                name="firstName"
                className="form-control"
                title="First Name"
                placeholder="e.g John"
                value={values.firstName}
                {...getFieldProps('firstName')}
              />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-3">
              {errors?.lastName && touched?.lastName ? (
                <label htmlFor="lastNameError">
                  <span className="required">{errors?.lastName}</span>
                </label>
              ) : (
                <label htmlFor="lastName">
                  Last Name{' '}
                  {errors.lastName || values.lastName === '' ? (
                    <span className="required">*</span>
                  ) : (
                    ''
                  )}
                </label>
              )}

              <input
                type="text"
                name="lastName"
                title="Last Name"
                className="form-control"
                placeholder="e.g Doe"
                value={values.lastName}
                {...getFieldProps('lastName')}
              />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-3">
              {errors?.idNumber && touched?.idNumber ? (
                <label htmlFor="idNumberError">
                  <span className="required">{errors?.idNumber}</span>
                </label>
              ) : (
                <label htmlFor="idNumber">
                  ID Number{' '}
                  {errors.idNumber || values.idNumber === '' ? (
                    <span className="required">*</span>
                  ) : (
                    ''
                  )}
                </label>
              )}

              <input
                type="text"
                name="idNumber"
                className="form-control"
                title="ID Number"
                placeholder="e.g 12-34567890-U-45"
                value={values.idNumber}
                {...getFieldProps('idNumber')}
              />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-3">
              {errors?.contactEmail && touched?.contactEmail ? (
                <label htmlFor="contactEmailError">
                  <span className="required">{errors?.contactEmail}</span>
                </label>
              ) : (
                <label htmlFor="contactEmail">
                  Email Address{' '}
                  {errors.contactEmail || values.contactEmail === '' ? (
                    <span className="required">*</span>
                  ) : (
                    ''
                  )}
                </label>
              )}
              <input
                type="email"
                name="contactEmail"
                title="Email Address"
                className="form-control"
                placeholder="e.g example@mail.info"
                value={values.contactEmail}
                {...getFieldProps('contactEmail')}
              />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-3">
              {errors?.phoneNumber && touched?.phoneNumber ? (
                <label htmlFor="phoneNumberError">
                  <span className="required">{errors?.phoneNumber}</span>
                </label>
              ) : (
                <label htmlFor="phoneNumber">
                  Phone Number{' '}
                  {errors.phoneNumber || values.phoneNumber === '' ? (
                    <span className="required">*</span>
                  ) : (
                    ''
                  )}
                </label>
              )}
              <input
                type="number"
                name="phoneNumber"
                className="form-control"
                title="Phone Number"
                placeholder="e.g 0717377207"
                value={values.phoneNumber}
                {...getFieldProps('phoneNumber')}
              />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-3">
              {errors?.billingAddress && touched?.billingAddress ? (
                <label htmlFor="billingAddressError">
                  <span className="required">{errors?.billingAddress}</span>
                </label>
              ) : (
                <label htmlFor="billingAddress">
                  Address{' '}
                  {errors.billingAddress || values.billingAddress === '' ? (
                    <span className="required">*</span>
                  ) : (
                    ''
                  )}
                </label>
              )}
              <input
                type="text"
                name="billingAddress"
                title="Address"
                className="form-control"
                placeholder="e.g 123 drive, 4th street, city"
                value={values.billingAddress}
                {...getFieldProps('billingAddress')}
              />
            </div>
            <div className="form-check pl-3 ml-2 mb-3 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
              {!values.termsAndConditions && (
                <input
                  type="checkbox"
                  className="form-check-input"
                  title="Terms and Conditions Consent"
                  name="termsAndConditions"
                  {...getFieldProps('termsAndConditions')}
                />
              )}
              {errors?.termsAndConditions && touched?.termsAndConditions ? (
                <label
                  htmlFor="termsAndConditionsError"
                  className="form-check-label required"
                >
                  {errors?.termsAndConditions}
                </label>
              ) : (
                <label
                  htmlFor="Terms And Terms Condition"
                  className="form-check-label"
                >
                  {values.termsAndConditions
                    ? `I agree to Terms & Conditions.`
                    : `Agree to Terms & Conditions.`}
                </label>
              )}
            </div>
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <NextButton
                next={props.next}
                onClick={() => {
                  formData.handleSubmit()
                }}
                disabled={
                  values.firstName === '' ||
                  values.lastName === '' ||
                  values.idNumber === '' ||
                  values.contactEmail === '' ||
                  values.phoneNumber === '' ||
                  values.billingAddress === '' ||
                  !values.termsAndConditions
                }
                errors={errors}
                values={values}
              />
            </div>
          </div>
        </form>
      </div>
    </li>
  )
}

export default EnquireForm
