import React, { useEffect, useState } from 'react'
import './CSS/Header.css'
import NavLinks from './Components/NavLinks'
import Logo from './Components/Logo'
import Hamburger from './Components/Hamburger'

const Header = () => {
  const [click, setClick] = useState(false)
  const handleClick = () => setClick(!click)
  const [reservation, setReservation] = useState('')

  useEffect(() => {
    const interval = setInterval(() => {
      const reservationStorage = localStorage.getItem('reservations')
      if (reservationStorage) {
        setReservation(reservationStorage)
      } else {
        setReservation(null)
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  return (
    <nav className="navigation-bar">
      <div className="navigation-container">
        <Logo />
        <NavLinks
          click={click}
          handleClick={handleClick}
          reservation={reservation}
          setClick={setClick}
        />
        <Hamburger click={click} handleClick={handleClick} />
      </div>
    </nav>
  )
}

export default Header
