import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { useHistory } from 'react-router'
import * as Yup from 'yup'
import Categories from './Categories/Categories'
import SearchForm from './Search/SearchForm'
import { fetchManufacturers } from '../../Services/api'
import PriceCategories from './Categories/PriceCategories'
import priceList from './Categories/PriceList.json'

const Sidenav = (props) => {
  const [loading, setLoading] = useState(false)
  const [manufacturers, setManufacturers] = useState([])
  const history = useHistory()
  const formData = useFormik({
    initialValues: {
      searchField: '',
    },
    validationSchema: Yup.object().shape({
      searchField: Yup.string()
        .required('Search phrase cannot be empty.')
        .min(3, 'Search phrase is too short.')
        .max(20, 'Somehow this is too long'),
    }),
    onSubmit: (values) => {
      setLoading(true)
      if (typeof props.search === 'function') {
        props.search(values)
      }
      history.push(
        `/results?query=${encodeURI(values?.searchField)}&_sort=price:desc`,
      )
      setLoading(false)
    },
  })

  useEffect(() => {
    const getData = async () => {
      setLoading(true)
      const manufacturers = await fetchManufacturers()
      setManufacturers(manufacturers)
      setLoading(false)
    }
    getData()
  }, [])

  const { getFieldProps, touched, errors } = formData

  return (
    <aside className="col-left blog-side sidebar col-xl-3 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      {/* import filter, cart, categories */}
      <SearchForm
        errors={errors}
        formData={formData}
        touched={touched}
        getFieldProps={getFieldProps}
      />
      <Categories
        loading={loading}
        list={manufacturers}
        categoryPurpose="Shop By Make"
      />
      <PriceCategories
        loading={loading}
        list={priceList}
        categoryPurpose="Shop By Price"
      />
    </aside>
  )
}

export default Sidenav
