import React from 'react'
import { useHistory } from 'react-router'

const NotFound = () => {
  const history = useHistory()
  return (
    <div className="row justify-content-center">
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-8 col-xs-8">
        <div className="">
          <img
            className="card-img img-responsive-custom"
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZL34PQR_fxbhKruj6BGlqzDC6KBiOZQIfwA&usqp=CAU"
            alt="No Reservation Found"
          />
        </div>
        <div className="card-body">
          <button
            className="btn button-fullwidth"
            onClick={() => history.push(`/`)}
          >
            Place a Reservation
          </button>
        </div>
      </div>
    </div>
  )
}

export default NotFound
