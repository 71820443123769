import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { NotificationManager } from 'react-notifications'

const PayPalButton = window.paypal.Buttons.driver('react', { React, ReactDOM })

const PayPalPayment = ({ total, onSuccess }) => {
  const onCreateOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: total,
          },
        },
      ],
    })
  }

  const onApproveOrder = async (data, actions) => {
    await actions.order.capture()
    onSuccess()
    NotificationManager.success(
      'Your payment has been successful.',
      'SUCCESS',
      3000,
    )
  }

  const onUserCancel = () => {
    NotificationManager.error('Payment was cancelled.', 'ERROR', 3000)
  }

  return (
    <div className="row justify-content-end">
      <div className="col-xl-7 col-lg-7 col-md-4 col-sm-12 col-xs-12 ">
        <PayPalButton
          createOrder={onCreateOrder}
          onApprove={onApproveOrder}
          onCancel={onUserCancel}
        />
      </div>
    </div>
  )
}

PayPalPayment.propTypes = {
  total: PropTypes.number.isRequired,
}

export default PayPalPayment
