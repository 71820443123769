import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  fetchReservation,
  patchReservation,
  purchaseCar,
} from '../../Services/api'
import TableHeader from '../Reuseable/TableHeader/TableHeader'
import OrderDetails from './Components/OrderDetails'
import NotFound from './Components/NotFound'
import PayPalPayment from '../Payments/PayPalPayment'
import Preloader from '../Preloader/Preloader'
import { CarContext } from '../../Contexts/CarContext'

const Orders = () => {
  const [reservation, setReservation] = useState([])
  const [refresh, setRefresh] = useState(0)
  const [loading, setLoading] = useState(false)
  const { reservationKey } = useParams()
  const history = useHistory()
  const { car } = useContext(CarContext)
  const { items = [] } = car
  useEffect(() => {
    const getReservation = async () => {
      try {
        setLoading(true)
        const dataRes = await fetchReservation()
        const dataResult = dataRes.forEach((item) => {
          if (item?.reservationKey === reservationKey) {
            setReservation(item)
          }
        })
        setLoading(false)
      } catch (error) {
        console.error(error)
      }
    }
    getReservation()
  }, [reservation?.reservationKey])
  const handlePaymentSuccess = async () => {
    try {
      setLoading(true)
      const data = await patchReservation(reservationKey)
      if (data) {
        setRefresh(refresh + 1)
        if (items[0]?.referenceNumber) {
          const purchaseData = await purchaseCar(items[0]?.referenceNumber)
          if (purchaseData) {
            history.push('/success')
          }
        }
      }
      setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }
  return (
    <section
      className="col-main col-xl-9 col-lg-9 col-md-12 col-sm-12 col-xs-12"
      style={{ visibility: 'visible' }}
    >
      <div className="my-account">
        {loading ? (
          <Preloader />
        ) : (
          <>
            {reservation?.reservationKey ? (
              <div className="dashboard">
                <TableHeader reservation={reservation} loading={loading} />
                <div className="recent-orders">
                  <OrderDetails
                    car={items[0]}
                    reservation={reservation}
                    loading={loading}
                  />
                  {reservation?.paymentMethod === 'paypal' &&
                    !reservation?.isPaid && (
                      <PayPalPayment
                        total={parseFloat(reservation?.total)}
                        onSuccess={handlePaymentSuccess}
                      />
                    )}
                  {reservation?.paymentMethod === 'cash' &&
                    !reservation?.isPaid && (
                      <div className="row justify-content-end">
                        <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-xs-12">
                          <button
                            className="btn continue button-fullwidth"
                            type="button"
                          >
                            PAYING CASH ON SITE
                          </button>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            ) : (
              <NotFound />
            )}
          </>
        )}
      </div>
    </section>
  )
}

export default Orders
