import React from 'react'
import Sidenav from '../Components/Sidenav/Sidenav'
import Orders from '../Components/Orders/Orders'

const OrdersPage = () => {
  return (
    <section className="main-container col2-left-layout bounceInUp animated">
      <div className="container">
        <div className="row justify-content-center">
          <Sidenav />
          <Orders />
        </div>
      </div>
    </section>
  )
}

export default OrdersPage
