import React from 'react'
import NextButton from '../../Buttons/Steps/NextButton'
import PrevButton from '../../Buttons/Steps/PrevButton'
import CheckoutButton from '../../Buttons/Checkout/CheckoutButton'
import StepHeader from './StepHeader'
import PersonalDetails from './Review/PersonalDetails'
import CarSelected from './Review/CarSelected'

const Review = (props) => {
  const { errors, values, formData } = props
  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])
  return (
    <li id="opc-billing" className="section allow active">
      <StepHeader
        title="REVIEW YOUR DETAILS"
        current={props.current}
        size={props.size}
        jump={props.jump}
      />
      <div id="checkout-step-billing" className="step a-item">
        <div id="accordion">
          <PersonalDetails values={values} />
          <CarSelected />
          {/* Buttons */}
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-3">
              <PrevButton prev={props.prev} />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              {props.size > 2 ? (
                <NextButton next={props.next} errors={errors} values={values} />
              ) : (
                <CheckoutButton
                  formData={formData}
                  disabled={formData.isSubmitting}
                  message="Submit Your Enquiry Now"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </li>
  )
}

export default Review
