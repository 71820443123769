import React from 'react'

const CallToAction = () => {
  return (
    <div className="newsletter-row">
      <div className="container">
        <div className="row">
          {/* <!-- Footer Newsletter --> */}
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col1">
            <div className="newsletter-wrap">
              <h4>We Do It All For</h4>
              <h5>YOU</h5>
              <h4>Buying Or Selling Cars Online In Zimbabwe</h4>
            </div>
            {/* <!--newsletter-wrap-->  */}
          </div>
        </div>
      </div>
      {/* <!--footer-column-last-->  */}
    </div>
  )
}

export default CallToAction
