import React, { useEffect, useState } from 'react'

const Pagination = ({
  total,
  itemsPerPage,
  setItemsPerPage,
  onPaginationChange,
}) => {
  const [counter, setCounter] = useState(1)
  const numberOfButtons = Math.ceil(total / itemsPerPage)
  useEffect(() => {
    const value = itemsPerPage * counter
    onPaginationChange(value - itemsPerPage, value)
  }, [counter])

  const onNavigate = (type) => {
    if (type === 'prev') {
      if (counter === 1) {
        setCounter(1)
      } else {
        setCounter(counter - 1)
      }
    } else if (type === 'next') {
      if (numberOfButtons === counter) {
        setCounter(counter)
      } else {
        setCounter(counter + 1)
      }
    } else {
      console.log(type)
    }
  }
  return (
    <div className="toolbar bottom container-fluid">
      <div className="pager row justify-content-center">
        <div className="pages col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <label>Page:</label>
          <ul className="pagination">
            <li>
              <a onClick={() => onNavigate('prev')}>«</a>
            </li>
            {new Array(numberOfButtons).fill('').map((unKnown, index) => (
              <li
                className={`${index + 1 === counter ? 'active' : null}`}
                key={index + 1}
              >
                <a onClick={() => setCounter(index + 1)}>{index + 1}</a>
              </li>
            ))}
            <li>
              <a onClick={() => onNavigate('next')}>»</a>
            </li>
          </ul>
        </div>

        <div
          className="pages col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12"
          id="limiter"
        >
          <label>View: </label>
          <ul>
            <li>
              {itemsPerPage}
              <ul className="view-items-per-page">
                <li
                  onClick={() => {
                    setItemsPerPage(20)
                    setCounter(1)
                  }}
                >
                  20
                </li>
                <li
                  onClick={() => {
                    setItemsPerPage(30)
                    setCounter(1)
                  }}
                >
                  30
                </li>
                <li
                  onClick={() => {
                    setItemsPerPage(40)
                    setCounter(1)
                  }}
                >
                  40
                </li>
                <li
                  onClick={() => {
                    setItemsPerPage(50)
                    setCounter(1)
                  }}
                >
                  50
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Pagination
