import React from 'react'

const PrevButton = ({ prev }) => {
  return (
    <button
      type="button"
      title="Next"
      className="btn continue button-fullwidth"
      onClick={prev}
    >
      <i className="fa fa-long-arrow-left"></i> PREVIOUS
    </button>
  )
}

export default PrevButton
