import React, { useEffect } from 'react'
import StepHeader from './StepHeader'
import Message from './Cards/Message'
import PrevButton from '../../Buttons/Steps/PrevButton'
import PaymentMethodButton from '../../Buttons/Steps/PaymentMethodButton'

const PaymentMethod = (props) => {
  const { formData, errors, touched, getFieldProps, values } = props
  const tomorrow = new Date(
    new Date().getTime() + 24 * 60 * 60 * 1000,
  ).getTime()

  const formatDate = new Date(tomorrow)
    .toString()
    .replace('GMT+0200 (Central Africa Time)', ' ')
  const countDownDate = new Date(tomorrow)

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])

  const messageTitle =
    'We expect your payment by ' +
    formatDate?.substring(0, 21)?.toUpperCase() +
    ' GMT+0200 (Central Africa Time).'
  //   {formatDate?.substring(4, 16)}
  //   {formatDate?.substring(16, 21)}
  const messageBody =
    'The selected vehicle will be reserved for 24 hours after placing this order. If you are going to make payment after a lapse of 24 hours, please inform us your intention to pay and the schedule of payment through whatsapp.'
  return (
    <li id="opc-billing" className="section allow active">
      <StepHeader
        title="Payment Details"
        current={props.current}
        size={props.size}
      />
      <Message title={messageTitle} message={messageBody} cardType="warning" />
      <br />
      <div id="checkout-step-billing" className="step a-item">
        <form>
          <fieldset className="group-select">
            <ul>
              <li id="billing-new-address-form">
                <fieldset>
                  <ul>
                    <li className="fields">
                      <div className="field">
                        <label htmlFor="paymentMethod">
                          Which payment method are you likely going to use ?
                        </label>
                        <br />
                        <div className="input-box">
                          <label className="mr-label">
                            <input
                              type="radio"
                              name="paymentMethod"
                              checked={values.paymentMethod === 'cash'}
                              value="cash"
                              onChange={(e) =>
                                formData.setFieldValue('paymentMethod', 'cash')
                              }
                            />
                            <span>Cash</span>
                          </label>
                          <label className="mr-label">
                            <input
                              type="radio"
                              name="paymentMethod"
                              checked={values.paymentMethod === 'paypal'}
                              value="paypal"
                              onChange={(e) =>
                                formData.setFieldValue(
                                  'paymentMethod',
                                  'paypal',
                                )
                              }
                            />
                            <span>PayPal</span>
                          </label>
                        </div>
                      </div>
                    </li>
                    {values?.paymentMethod !== 'cash' && (
                      <li className="fields">
                        <div className="input-box mb-3">
                          <label htmlFor="accountNumber">
                            Why did you choose this payment method ? (Optional)
                          </label>
                          <input
                            type="text"
                            title="Why Payment Method"
                            name="whyPayment"
                            {...getFieldProps('whyPayment')}
                            placeholder="Your answer can be as short as possible"
                            className="input-text required-entry"
                          />
                        </div>
                      </li>
                    )}
                  </ul>
                </fieldset>
              </li>
              <div className="btns-set" id="billing-buttons-container">
                <div className="row justify-content-center">
                  <div className="col-lg-6 mb-3">
                    <PrevButton prev={props.prev} />
                  </div>
                  <div className="col-lg-6">
                    <PaymentMethodButton
                      next={props.next}
                      onClick={() => {
                        formData.handleSubmit()
                      }}
                      disabled={values.paymentMethod === ''}
                      errors={errors}
                      values={values}
                    />
                  </div>
                </div>
              </div>
            </ul>
          </fieldset>
        </form>
      </div>
    </li>
  )
}

export default PaymentMethod
