import React, { useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { NavLink } from 'react-router-dom'

const Categories = ({ categoryPurpose, list, loading }) => {
  const [open, setOpen] = useState(false)
  return (
    <div>
      {/* <!-- BEGIN SIDE-NAV-CATEGORY --> */}
      <div className="side-nav-categories">
        <div
          className={!loading ? 'block-title' : 'block-title text-center'}
          onClick={() => setOpen(!open)}
          style={{ cursor: 'pointer' }}
        >
          {!loading ? (
            <>
              {' '}
              <i className="fa fa-bars"></i>
              {' ' + categoryPurpose}
            </>
          ) : (
            <i className="fa fa-spinner fa-spin"></i>
          )}
        </div>
        {/* <!--block-title-->  */}
        {/* <!-- BEGIN BOX-CATEGORY --> */}
        {open && !loading && (
          <div className="box-content box-category">
            <AnimatePresence>
              <ul>
                {list.length > 0 &&
                  list.map((item, i) => (
                    <motion.li
                      variants={{
                        hidden: {
                          opacity: 0,
                          y: -50,
                        },
                        visible: (i) => ({
                          opacity: 1,
                          y: 0,
                          transition: {
                            delay: i * 0.1,
                          },
                        }),
                      }}
                      initial="hidden"
                      animate="visible"
                      custom={i}
                      key={item?.id}
                    >
                      {item?.cars?.filter((car) => {
                        return car?.isAvailable
                      })?.length > 0 && (
                        <NavLink
                          to={
                            '/results?manufacturer.slug_contains=' +
                            item?.slug +
                            '&_sort=price:desc'
                          }
                          className="active img-responsive-custom-2"
                          onClick={() => setOpen(!open)}
                        >
                          {item?.logo && (
                            <img src={item?.logo?.url} className="img-circle" />
                          )}{' '}
                          <span className="text-center">
                            {' ' + item?.name}
                          </span>
                          <span className="float-right">
                            {`${
                              item?.cars?.filter((car) => {
                                return car?.isAvailable
                              })?.length
                            }`}
                            {/* {`(${item?.cars.length})`} */}
                          </span>
                        </NavLink>
                      )}
                    </motion.li>
                  ))}
              </ul>
            </AnimatePresence>
          </div>
        )}
        {/* <!--box-content box-category-->  */}
      </div>
      {/* <!--side-nav-categories--> */}
    </div>
  )
}

export default Categories
