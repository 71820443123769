import React from 'react'

const PaymentMethodButton = ({ next, values, errors, onClick, disabled }) => {
  return (
    <React.Fragment>
      {values?.paymentMethod === '' || errors?.paymentMethod ? (
        <button
          type="button"
          title="Next"
          // disabled={disabled}
          className="btn continue button-fullwidth"
          onClick={
            values?.paymentMethod === '' || !errors?.paymentMethod
              ? onClick
              : next
          }
        >
          NEXT <i className="fa fa-long-arrow-right"></i>
        </button>
      ) : (
        <button
          type="button"
          title="Next"
          disabled={disabled}
          className="btn continue button-fullwidth"
          onClick={
            values?.paymentMethod !== '' || !errors?.paymentMethod
              ? next
              : console.log('LOGGED NOT SUPPOSED TO HAPPEN')
          }
        >
          NEXT <i className="fa fa-long-arrow-right"></i>
        </button>
      )}
    </React.Fragment>
  )
}

export default PaymentMethodButton
