import React, { useEffect, useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import Preloader from '../../Preloader/Preloader'
import { NotificationManager } from 'react-notifications'

const OrderDetails = ({ loading, car, reservation }) => {
  const reservationStorage = localStorage?.getItem('reservations')
  const history = useHistory()
  const [time, setTime] = useState('')
  useEffect(() => {
    let countDownDate = new Date(
      `${localStorage?.getItem('reservations')}`,
    )?.getTime()
    let x = setInterval(function () {
      let today = new Date().getTime()
      let distance = countDownDate - today
      let hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
      )
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      let seconds = Math.floor((distance % (1000 * 60)) / 1000)
      setTime(hours + ' h :  ' + minutes + ' m :  ' + seconds + ' s Remaining')
      if (distance < 0) {
        clearInterval(x)
        setTime('Reservation time expired')
        history.push('/restricted')
        NotificationManager.error('You have been blacklisted', 'BLOCKED', 3000)
      }
    }, 1000)
  }, [])
  if (loading) {
    return <Preloader />
  }
  return (
    <div
      className={
        reservation?.isPaid ? 'row justify-content-center mb-4' : 'row g-0 mb-4'
      }
    >
      {!reservation?.isPaid && (
        <div className="col-xl-5 col-lg-5 col-md-12 img-responsive-custom m-0">
          <NavLink
            to={'/cars/' + reservation?.car?.referenceNumber}
            title={`${car?.manufacturer?.name} ${car?.version?.name}`}
            className="mb-5"
          >
            <img
              src={reservation?.car?.photos[0]?.url}
              className="card-img"
              alt={`${car?.manufacturer?.name} ${car?.version?.name}`}
            />
          </NavLink>
          {car?.specs ? (
            <h6 className="float-left mt-2">
              {' ' + car?.manufacturer?.name + ' '}
              {' ' + car?.specs + ' '}, REFERENCE{' '}
              <i>{' # ' + car?.referenceNumber}</i>
            </h6>
          ) : (
            <h6 className="float-left mt-2">
              {' ' + car?.manufacturer?.name + ' '}
              {' ' + car?.version?.name + ' '}, REFERENCE{' '}
              <i>{' # ' + car?.referenceNumber}</i>
            </h6>
          )}
        </div>
      )}
      <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-xs-12">
        <div className="card-body m-0 p-0">
          <ul className="list-group">
            {reservation?.isPaid && (
              <li className="list-group-item">
                CAR REF # :
                <span className="float-right">
                  {reservation?.car?.referenceNumber}
                </span>
              </li>
            )}
            {reservation?.idNumber && (
              <li className="list-group-item">
                ID Number :
                <span className="float-right">{reservation?.idNumber}</span>
              </li>
            )}
            {reservation?.contactEmail && (
              <li className="list-group-item">
                Email Address :
                <span className="float-right">{reservation?.contactEmail}</span>
              </li>
            )}
            {reservation?.phoneNumber && (
              <li className="list-group-item">
                Phone Number :
                <span className="float-right">
                  +263{reservation?.phoneNumber}
                </span>
              </li>
            )}
            {reservation?.billingAddress && (
              <li className="list-group-item">
                Address :
                <span className="float-right">
                  {reservation?.billingAddress}
                </span>
              </li>
            )}
            <li className="list-group-item">
              Payment Status :
              <span className="float-right">
                {reservation?.isPaid ? 'PAID' : 'NOT PAID'}
              </span>
            </li>
            {reservation?.paymentMethod === 'paypal' ? (
              <li className="list-group-item">
                Payment Method :
                <span className="float-right">
                  {reservation?.paymentMethod?.toUpperCase()}
                </span>
              </li>
            ) : (
              <li className="list-group-item">
                Payment Method :
                <span className="float-right">
                  {reservation?.paymentMethod?.toUpperCase()}
                </span>
              </li>
            )}
            {reservation?.total && (
              <li className="list-group-item">
                Total Amount Due :
                <span className="float-right">US $ {reservation?.total}</span>
              </li>
            )}
            {reservationStorage !== 'Reservation time expired' &&
            reservationStorage ? (
              <li className="list-group-item">
                Time Left :<span className="float-right">{time}</span>
              </li>
            ) : (
              ''
            )}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default OrderDetails
