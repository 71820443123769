import React from 'react'
import { Steps, Step } from 'react-step-builder'
import EnquireForm from '../Reuseable/Form/MultiStep/EnquireForm'
import Review from '../Reuseable/Form/MultiStep/Review'
const Enquire = ({ errors, values, touched, getFieldProps, formData }) => {
  return (
    <div className="main-container col2-right-layout">
      <div className="main container">
        <div className="row justify-content-center">
          <section className="col-main col-xl-9 col-lg-9 col-md-9 col-sm-12 col-xs-12 wow bounceInUp animated animated">
            <ol className="one-page-checkout" id="checkoutSteps">
              <Steps>
                <Step
                  component={EnquireForm}
                  formData={formData}
                  errors={errors}
                  touched={touched}
                  getFieldProps={getFieldProps}
                  values={values}
                />
                <Step
                  component={Review}
                  formData={formData}
                  errors={errors}
                  touched={touched}
                  getFieldProps={getFieldProps}
                  values={values}
                />
              </Steps>
            </ol>
            <br />
          </section>
        </div>
        {/* <!--row-->    */}
      </div>
      {/* <!--main-container-inner--> */}
    </div>
  )
}

export default Enquire
