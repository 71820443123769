import React from 'react'

const CardHeader = ({ reviewStageIcon, reviewStageTitle, show, setShow }) => {
  return (
    <div id="headingTwo">
      <h5 className="mb-0">
        <button
          className="btn continue button-fullwidth"
          onClick={() => {
            setShow(!show)
          }}
        >
          {reviewStageTitle} <i className={'fa fa-' + reviewStageIcon}></i>
        </button>
      </h5>
    </div>
  )
}

export default CardHeader
