import React from 'react'
import Sidenav from '../Components/Sidenav/Sidenav'
import Success from '../Components/Success/Success'

const SuccessPage = () => {
  localStorage.removeItem('buying')
  localStorage.removeItem('reservations')
  localStorage.removeItem('reservationKey')
  return (
    <section className="main-container col2-left-layout bounceInUp animated">
      <div className="container">
        <div className="row justify-content-center">
          <Sidenav />
          <Success />
        </div>
      </div>
    </section>
  )
}

export default SuccessPage
