import React from 'react'

const CheckoutButton = ({ formData, disabled, message }) => {
  return (
    <button
      type="submit"
      title="Continue"
      disabled={disabled}
      className="btn continue button-fullwidth"
      onClick={formData.handleSubmit}
    >
      <span>{!formData?.isSubmitting && message}</span>{' '}
      <i
        className={
          formData?.isSubmitting
            ? 'fa fa-spinner fa-spin'
            : 'fa fa-angle-double-right'
        }
      ></i>
    </button>
  )
}

export default CheckoutButton
