import React from 'react'
import { Redirect, Route } from 'react-router-dom'

const ProtectedLogin = ({ path, user, component: Component, ...rest }) => {
  console.log('user', user)
  return (
    <Route
      {...rest}
      render={() =>
        !user?.isAuthenticated ? <Component /> : <Redirect to={'/dashboard'} />
      }
    />
  )
}

export default ProtectedLogin
