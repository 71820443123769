import React from 'react'
import { NavLink } from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination } from 'swiper/core'
import Preloader from '../../Preloader/Preloader'
import AddCar from '../Buttons/AddCar'
import EnquireCar from '../Buttons/EnquireCar'
import SoldCar from '../Components/SoldCar'

import 'swiper/swiper.min.css'
import 'swiper/components/pagination/pagination.min.css'
import 'swiper/components/navigation/navigation.min.css'
import '../Styles/swiper.css'

SwiperCore.use([Navigation, Pagination])

const Car = ({ cars, loading, itemsPerPage, pagination }) => {
  const capitalizeFirstLetter = (string) => {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1)
  }

  if (loading) {
    return <Preloader />
  }
  return (
    <Swiper
      slidesPerView={4}
      spaceBetween={10}
      autoHeight={true}
      navigation={true}
      cssMode={true}
      style={{
        '--swiper-navigation-color': '#14BAFF',
        '--swiper-pagination-color': '#14BAFF',
      }}
      pagination={{
        type: 'progressbar',
      }}
      className="container-fluid"
      breakpoints={{
        '@0.00': {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        '@0.75': {
          slidesPerView: 4,
          spaceBetween: 5,
        },
        '@1.00': {
          slidesPerView: 4,
          spaceBetween: 5,
        },
        '@1.50': {
          slidesPerView: 3,
          spaceBetween: 5,
        },
      }}
    >
      <AnimatePresence className="row">
        {cars.length > 0 &&
          cars
            .slice(
              pagination.start,
              itemsPerPage > pagination.end ? itemsPerPage : pagination.end,
            )
            .map((car, i) => (
              <SwiperSlide
                key={car?.referenceNumber}
                className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12"
              >
                <motion.li
                  className="item"
                  key={car?.referenceNumber}
                  variants={{
                    hidden: {
                      opacity: 0,
                      y: -50,
                    },
                    visible: (i) => ({
                      opacity: 1,
                      y: 0,
                      transition: {
                        delay: i * 0.1,
                      },
                    }),
                  }}
                  initial="hidden"
                  animate="visible"
                  custom={i}
                >
                  {car?.isAvailable ? (
                    <div className="item-inner">
                      <div className="item-img">
                        <div className="item-img-info img-responsive-custom">
                          <NavLink
                            to={'/cars/' + car?.referenceNumber}
                            title={`${car?.manufacturer?.name} ${car?.version?.name}`}
                          >
                            <img
                              src={car?.photos[0]?.url}
                              className="card-img-top"
                              alt={`${car?.manufacturer?.name} ${car?.version?.name}`}
                            />
                          </NavLink>
                          {car?.status === 'used' ? (
                            <div className="new-label used-status new-top-left">
                              {car?.status}
                            </div>
                          ) : (
                            <div
                              className={
                                car?.status !== 'new'
                                  ? 'new-label recent-status sale-top-left'
                                  : 'sale-label new-status sale-top-right'
                              }
                            >
                              {car?.status !== 'new'
                                ? 'RECENT IMPORT'
                                : car?.status}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="item-info">
                        <div className="info-inner">
                          <div className="item-title">
                            {car?.specs ? (
                              <NavLink
                                to={'/cars/' + car?.referenceNumber}
                                title={`${car?.version?.name}`}
                              >
                                {`${capitalizeFirstLetter(
                                  car?.manufacturer?.name,
                                )} ${capitalizeFirstLetter(car?.specs)}`}
                              </NavLink>
                            ) : (
                              <NavLink
                                to={'/cars/' + car?.referenceNumber}
                                title={`${car?.version?.name}`}
                              >
                                {`${capitalizeFirstLetter(
                                  car?.manufacturer?.name,
                                )} ${capitalizeFirstLetter(
                                  car?.version?.name,
                                )}`}
                              </NavLink>
                            )}
                          </div>
                          <div className="item-content">
                            <div className="item-price">
                              <div className="price-box">
                                <span className="regular-price">
                                  {car?.price ? (
                                    <span className="price">
                                      US$ {car?.price?.toFixed(2)}
                                    </span>
                                  ) : (
                                    <span className="price">On Offer</span>
                                  )}
                                </span>
                              </div>
                            </div>
                            {car?.price ? (
                              <AddCar product={car} />
                            ) : (
                              <EnquireCar product={car} />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <SoldCar car={car} />
                  )}
                </motion.li>
              </SwiperSlide>
            ))}
      </AnimatePresence>
    </Swiper>
  )
}

export default Car
