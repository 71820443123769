import React from 'react'
import { NavLink } from 'react-router-dom'
import Error404Image from './Images/Error404.png'

const Error404 = () => {
  return (
    <section className="content-wrapper" style={{ background: '#14BAFF ' }}>
      <div className="container">
        <div className="std">
          <div className="page-not-found">
            <div>
              <img src={Error404Image} />
            </div>
            <h3>The Page you requested was not found !</h3>
            <div>
              <NavLink to="/" className="btn-home">
                <span>BACK TO CARS</span>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Error404
