import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import 'react-notifications/lib/notifications.css'
import './index.css'
import App from './App'
import { CarProvider } from './Contexts/CarContext'
import { CurrentUserProvider } from './Contexts/UserContexts'

ReactDOM.render(
  <React.StrictMode>
    <CurrentUserProvider>
      <CarProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </CarProvider>
    </CurrentUserProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)
