import React, { useContext, useEffect } from 'react'
import { useFormik } from 'formik'
import { NotificationManager } from 'react-notifications'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'
import Checkout from '../Components/Checkout/Checkout'
import { CarContext } from '../Contexts/CarContext'
import { placeReservation, updateCar } from '../Services/api'

const CheckoutPage = () => {
  const idNumberRegex = /(?=.*[a-z])/
  const history = useHistory()
  const { car } = useContext(CarContext)
  const { items = [] } = car

  const tomorrow = new Date(
    new Date().getTime() + 24 * 60 * 60 * 1000,
  ).getTime()

  const formatDate = new Date(tomorrow)
    .toString()
    .replace('GMT+0200 (Central Africa Time)', ' ')

  let countDownDate = new Date(
    `${localStorage?.getItem('reservations')}`,
  )?.getTime()
  let today = new Date().getTime()
  let distance = countDownDate - today

  useEffect(() => {
    const effectData = () => {
      const reservationKey = localStorage?.getItem('reservationKey')
      if (reservationKey !== '' && reservationKey && distance > 0) {
        history.push(`/reservations/${reservationKey}`)
        NotificationManager.warning(
          'You have another reservation in progress',
          'WAIT',
          3000,
        )
      } else if (distance < 1) {
        history.push('/restricted')
        NotificationManager.error('You have been blacklisted', 'BLOCKED', 3000)
      }
    }
    effectData()
  })

  const formData = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      idNumber: '',
      contactEmail: '',
      phoneNumber: '',
      billingAddress: '',
      termsAndConditions: false,
      paymentMethod: 'cash',
      whyPayment: '',
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string()
        .required('First name is required.')
        .min(2, 'First name is too short.')
        .max(30, 'First name is too long.'),
      lastName: Yup.string()
        .required('Last name is required.')
        .min(4, 'Last name is too short.')
        .max(50, 'Last name is too long.'),
      idNumber: Yup.string()
        .required('ID Number is required.')
        .min(15, 'Should be 15 characters long including the - symbol.')
        .max(
          15,
          'Should not be greater than 15 characters including the - symbol.',
        ),
      contactEmail: Yup.string()
        .required('Please provide your email address.')
        .email('Please provide a valid email address.')
        .min(6, 'Email address is too short.')
        .max(50, 'Email address is too long.'),
      phoneNumber: Yup.number()
        .required('Phone number is required.')
        .min(242000000, 'Phone number not valid.')
        .max(789999999, 'Phone number not valid.'),
      billingAddress: Yup.string()
        .required('Business / Residential address required.')
        .min(6, 'Address is too short')
        .max(100, 'Address is too long.'),
      termsAndConditions: Yup.bool().oneOf([true], 'Please agree to continue.'),
      paymentMethod: Yup.string().required(
        'Please select preferred payment method.',
      ),
      whyPayment: Yup.string()
        .min(5, 'Your answer is too short.')
        .max(100, 'Your answer is too long.'),
    }),
    onSubmit: async (values) => {
      try {
        // 1. update the car to the one in context
        // 2. send data to api
        // 3. notify using email
        const updateCarData = await updateCar(items[0]?.referenceNumber)
        if (updateCarData) {
          const reservation = await placeReservation({
            ...values,
            total: `${items[0]?.price?.toString()}`,
            timeOfReservation: `${formatDate?.substring(0, 21)}`,
            isPaid: false,
            car: items[0],
          })

          if (reservation) {
            history.push(`/reservations/${reservation?.reservationKey}`)
            NotificationManager.success(
              'Reservation completed',
              'Success',
              3000,
            )
            localStorage.setItem('reservations', reservation?.timeOfReservation)
            localStorage.setItem('reservationKey', reservation?.reservationKey)
          }
        }
      } catch (error) {
        history.push(`/checkout/${items[0]?.referenceNumber}`)
        NotificationManager.error(
          'Failed to complete reservation',
          'Error',
          3000,
        )
      }
    },
  })
  const { getFieldProps, setFieldValue, errors, touched } = formData
  return (
    <Checkout
      formData={formData}
      errors={errors}
      touched={touched}
      getFieldProps={getFieldProps}
      values={formData.values}
      setFieldValue={setFieldValue}
    />
  )
}

export default CheckoutPage
