import React, {
  useEffect,
  useReducer,
  useContext,
  createContext,
  useState,
} from 'react'
import { auth } from '../Services/auth'

const CurrentUserStateContext = createContext()
const CurrentUserDispatchContext = createContext()

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      return { ...action.user, isAuthenticated: true }
    case 'LOGOUT':
      return { isAuthenticated: false }
    default:
      throw new Error(`unknown action ${action.type}`)
  }
}

export const CurrentUserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, { isAuthenticated: false })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchUser = async () => {
      setLoading(true)
      const user = await auth('/users/me', 'GET')
      if (user.id) {
        dispatch({ type: 'LOGIN', user })
        return
      }
      setLoading(false)
    }
    fetchUser()
  }, [])

  return (
    <CurrentUserDispatchContext.Provider value={dispatch}>
      <CurrentUserStateContext.Provider value={state}>
        {children}
      </CurrentUserStateContext.Provider>
    </CurrentUserDispatchContext.Provider>
  )
}

export const useCurrentUser = () => useContext(CurrentUserStateContext)
export const useDispatchCurrentUser = () =>
  useContext(CurrentUserDispatchContext)
