import React from 'react'
import { NavLink } from 'react-router-dom'
import RestrictedImage from './Images/Restricted.png'
import './Styles/styles.css'

const Restricted = () => {
  return (
    <section className="content-wrapper" style={{ background: '#14BAFF ' }}>
      <div className="container">
        <div className="std">
          <div className="page-not-found">
            <h1>STOP !</h1>
            <img src={RestrictedImage} className="card-img-top" />
            <h3 className="text-white mt-4 text-center">
              You have been restricted and no longer eligible to place a
              reservation. Please contact adminstrator for more info.
            </h3>
            <div>
              <NavLink to="/" className="btn-home">
                <span>BROWSE CARS</span>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Restricted
