import React from 'react'
import { Steps, Step } from 'react-step-builder'
import PersonalDetails from '../Reuseable/Form/MultiStep/PersonalDetails'
import PaymentMethod from '../Reuseable/Form/MultiStep/PaymentMethod'
import Review from '../Reuseable/Form/MultiStep/Review'
import FinalStep from '../Reuseable/Form/MultiStep/FinalStep'

const Checkout = ({ formData, errors, touched, getFieldProps, values }) => {
  return (
    <div className="main-container col2-right-layout">
      <div className="main container">
        <div className="row justify-content-center">
          <section className="col-main col-xl-9 col-lg-9 col-md-9 col-sm-12 col-xs-12">
            <ol className="one-page-checkout" id="checkoutSteps">
              <Steps>
                <Step
                  component={PersonalDetails}
                  formData={formData}
                  errors={errors}
                  touched={touched}
                  getFieldProps={getFieldProps}
                  values={values}
                />
                <Step
                  component={PaymentMethod}
                  formData={formData}
                  errors={errors}
                  touched={touched}
                  getFieldProps={getFieldProps}
                  values={values}
                />
                <Step
                  component={Review}
                  formData={formData}
                  errors={errors}
                  touched={touched}
                  getFieldProps={getFieldProps}
                  values={values}
                />
                <Step
                  component={FinalStep}
                  formData={formData}
                  getFieldProps={getFieldProps}
                  values={values}
                />
              </Steps>
            </ol>

            <br />
          </section>
        </div>
        {/* <!--row-->    */}
      </div>
      {/* <!--main-container-inner--> */}
    </div>
  )
}

export default Checkout
