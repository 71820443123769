export const auth = async (path, method, body) => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}${path}`, {
    method,
    headers: {
      'content-type': 'application/json',
    },
    withCredentials: true,
    body: JSON.stringify(body),
  })
  const data = await response.json()
  return data
}
