import React, { useState, createContext } from 'react'
import { useHistory } from 'react-router'

const STORAGE_ITEM = 'buying'
export const CarContext = createContext()

export const CarProvider = ({ children }) => {
  const history = useHistory()
  const initialItems = JSON.parse(localStorage.getItem(STORAGE_ITEM)) || []

  const calculateCartTotal = (items) => {
    localStorage.setItem(STORAGE_ITEM, JSON.stringify(items))

    const usdCartTotal = items.reduce((prev, curr) => prev + curr.price, 0)

    return { usdCartTotal }
  }

  const [car, setCar] = useState({
    items: initialItems,
    ...calculateCartTotal(initialItems),
  })

  const addCar = (product) => {
    let { items = [] } = car
    const carIndex = items.findIndex((item) => item.id === product.id)
    if (carIndex === -1) {
      if (items.length > 0) {
        // 1. empty the current content of the array
        items = []
        if ((items = [])) {
          // 2. set the content to new items
          items.push({
            ...product,
            isReserved: true,
          })
          // 3. push to local storage array with new content
        }
      } else {
        items.push({
          ...product,
          isReserved: true,
        })
      }
    } else if (items[carIndex].isReserved !== product.isReserved) {
      history.push('/checkout')
    }
    setCar({
      items,
      ...calculateCartTotal(items),
    })
  }

  const removeCar = (product) => {
    const { items = [] } = car
    const carIndex = items.findIndex((item) => item.id === product.id)

    if (carIndex !== -1) {
      items.splice(carIndex, 1)
    }

    setCar({
      items,
      ...calculateCartTotal(items),
    })
  }

  return (
    <CarContext.Provider value={{ car, addCar, removeCar }}>
      {children}
    </CarContext.Provider>
  )
}
