import React from 'react'

const SoldCar = ({ car }) => {
  return (
    <div className="car">
      <img
        className="card-img"
        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRUixeNLH7s6tRsSRBuHIct6fEb9J08bZdlXA&usqp=CAU"
        alt="SOLD CAR"
      />
      <div className="footer">
        <p>
          {car?.manufacturer?.name}{' '}
          {car?.specs ? car?.specs : car?.version?.name}
        </p>
        <p>For US ${car?.price}</p>
      </div>
    </div>
  )
}

export default SoldCar
