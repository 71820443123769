import React, { useState } from 'react'
import CardHeader from './CardHeader'

const PersonalDetails = ({ values }) => {
  const [show, setShow] = useState(false)
  return (
    <div className="card mb-3">
      <CardHeader
        reviewStageIcon={!show ? 'chevron-up' : 'chevron-down'}
        reviewStageTitle="Personal Details"
        show={show}
        setShow={setShow}
      />
      <div className={!show ? 'collapse' : 'show'}>
        <div className="card-body">
          <ul className="list-group list-group-flush">
            <li className="list-group-item">
              First Name (s) :{' '}
              <span className="float-right">
                {values?.firstName.toUpperCase()}
              </span>
            </li>
            <li className="list-group-item">
              Last Name :{' '}
              <span className="float-right">
                {values?.lastName?.toUpperCase()}
              </span>
            </li>
            <li className="list-group-item">
              ID Number :{' '}
              <span className="float-right">{values?.idNumber}</span>
            </li>
            <li className="list-group-item">
              Email Address :{' '}
              <span className="float-right">{values?.contactEmail}</span>
            </li>
            <li className="list-group-item">
              Phone Number :{' '}
              <span className="float-right">{`0${values?.phoneNumber}`} </span>
            </li>
            <li className="list-group-item">
              Physical Address :{' '}
              <span className="float-right">{values?.billingAddress}</span>
            </li>
            <li className="list-group-item">
              Selected Payment :{' '}
              <span className="float-right">
                {values?.paymentMethod?.toUpperCase()}
              </span>
            </li>
            <li className="list-group-item">
              Ts & Cs Acceptance :{' '}
              <span className="float-right">
                {values?.termsAndConditions ? 'YES' : 'NO'}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default PersonalDetails
