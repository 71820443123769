import React from 'react'

const Preloader = () => {
    return (
        <div className="container-fluid">
            <div className="row justify-content-center">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div className="loader">
                        <div className="box">
                            <div className="circle" />
                        </div>
                        <div className="box">
                            <div className="circle" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Preloader
