import React from 'react';
import CallToAction from './Components/CallToAction';
import FooterCredits from './Components/FooterCredits';

const Footer = () => {
  return (
    <footer>
      {/* <!-- BEGIN INFORMATIVE FOOTER --> */}
      <div className="footer-inner">
        <CallToAction />
      </div>
      {/* <!--footer-inner-->*/}
      <FooterCredits />
      {/* <!-- BEGIN SIMPLE FOOTER -->  */}
    </footer>
  )
}

export default Footer
