import React from 'react'

const Hamburger = ({click, handleClick}) => {
    return (
        <div className="navigation-icon" onClick={handleClick}>
            <i className={click ? "fa fa-times" : "fa fa-bars"}></i>
        </div>
    )
}

export default Hamburger
