import React from 'react'

const Profile = ({ user }) => {
  return (
    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-3">
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">
            Account Details <i className="fa fa-user"></i>
          </h5>
          <p className="card-text">
            Update your dealership account details / settings from this tab.
          </p>
          <button type="submit" className="btn button-fullwidth">
            GO TO PROFILE
          </button>
        </div>
      </div>
    </div>
  )
}

export default Profile
