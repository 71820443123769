import React from 'react'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { NotificationManager } from 'react-notifications'
import Register from '../Components/Auth/Register'
import { auth } from '../Services/auth'
import { useDispatchCurrentUser } from '../Contexts/UserContexts'

const RegisterPage = () => {
  const history = useHistory()
  const lowerCaseRegex = /(?=.*[a-z])/
  const upperCaseRegex = /(?=.*[A-Z])/
  const numericRegex = /(?=.*[0-9])/

  const formData = useFormik({
    initialValues: {
      username: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object().shape({
      username: Yup.string()
        .required('Username  is required.')
        .min(4, 'Username is too short.')
        .matches(lowerCaseRegex, 'Must contain one lower case letter. a - z')
        .matches(upperCaseRegex, 'Must contain one upper case letter. A - Z'),
      email: Yup.string()
        .required('Email is required.')
        .email('Please provide a valid email.')
        .min(6, 'Email is too short.'),
      password: Yup.string()
        .required('Provide a password to aunthenticate.')
        .min(8, 'Minimum password is 8 characters.')
        .matches(lowerCaseRegex, 'Must contain one lower case letter. a - z')
        .matches(upperCaseRegex, 'Must contain one upper case letter. A - Z')
        .matches(numericRegex, 'Must contain one numeric value. 0 - 9'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password')], 'Passwords do not match.')
        .required('Confirm your password to continue.'),
    }),
    onSubmit: async (values) => {
      try {
        const res = await auth('/auth/local/register', 'POST', {
          username: values?.username,
          email: values?.email,
          password: values?.password,
        })
        if (res?.user) {
          history.push('/login')
          NotificationManager.success(
            'Successfully registered dealer.',
            'Success',
            3000,
          )
        } else {
          history.push('/Register')
          NotificationManager.warning('Failed to register', 'Oops', 3000)
        }
      } catch (error) {
        console.error(error)
        NotificationManager.error('An error occured.', 'Error', 3000)
      }
    },
  })
  const { errors, touched, getFieldProps, values } = formData
  return (
    <section className="container">
      <div className="row justify-content-center">
        <Register
          getFieldProps={getFieldProps}
          formData={formData}
          errors={errors}
          touched={touched}
          values={values}
        />
      </div>
    </section>
  )
}

export default RegisterPage
