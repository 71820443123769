import React, { useEffect, useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import {
  useCurrentUser,
  useDispatchCurrentUser,
} from '../../../Contexts/UserContexts'
import { NotificationManager } from 'react-notifications'
import { auth } from '../../../Services/auth'

const NavLinks = ({ click, setClick, handleClick, reservation }) => {
  const user = useCurrentUser()
  const dispatch = useDispatchCurrentUser()
  const [loading, setLoading] = useState(false)

  const handleLogout = async () => {
    try {
      setLoading(true)
      setClick(!click)
      await auth('/logout', 'POST')
      dispatch({ type: 'LOGOUT' })
      NotificationManager.success('Successfully logged out', 'Success', 3000)
      setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <ul className={click ? 'navigation-menu active' : 'navigation-menu'}>
      {!user?.isAuthenticated ? (
        <React.Fragment>
          <li className="navigation-item">
            <NavLink
              exact
              to="/"
              activeClassName="active"
              className="navigation-links"
              onClick={handleClick}
            >
              Home
            </NavLink>
          </li>
          {reservation && (
            <li className="navigation-item">
              <NavLink
                exact
                to="/track"
                activeClassName="active"
                className="navigation-links"
                onClick={handleClick}
              >
                Find My Reservation
              </NavLink>
            </li>
          )}
          <li className="navigation-item">
            <NavLink
              exact
              to="/terms-and-conditions"
              activeClassName="active"
              className="navigation-links"
              onClick={handleClick}
            >
              Ts & Cs
            </NavLink>
          </li>
          <li className="navigation-item">
            <NavLink
              exact
              to="/policy"
              activeClassName="active"
              className="navigation-links"
              onClick={handleClick}
            >
              Private Policy
            </NavLink>
          </li>
          <li className="navigation-item">
            <NavLink
              exact
              to="/contact"
              activeClassName="active"
              className="navigation-links"
              onClick={handleClick}
            >
              Contact Us
            </NavLink>
          </li>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <li className="navigation-item">
            <NavLink
              exact
              to="/dashboard"
              activeClassName="active"
              className="navigation-links"
              onClick={handleClick}
            >
              Dashboard
            </NavLink>
          </li>
          <li className="navigation-item">
            <NavLink
              exact
              to="/listed"
              activeClassName="active"
              className="navigation-links"
              onClick={handleClick}
            >
              Uploaded Cars
            </NavLink>
          </li>

          <li className="navigation-item">
            <NavLink
              exact
              to="/transactions"
              activeClassName="active"
              className="navigation-links"
              onClick={handleClick}
            >
              Transactions
            </NavLink>
          </li>
          <li className="navigation-item">
            <NavLink
              exact
              to="/profile"
              activeClassName="active"
              className="navigation-links"
              onClick={handleClick}
            >
              Profile
            </NavLink>
          </li>
        </React.Fragment>
      )}
      <li className="navigation-item">
        {!user?.isAuthenticated ? (
          <NavLink
            exact
            to="/login"
            activeClassName="active"
            className="navigation-links"
            onClick={handleClick}
          >
            {!loading && 'Login '}
            <i className={loading ? 'fa fa-spinner fa-spin' : 'fa fa-lock'}></i>
          </NavLink>
        ) : (
          <NavLink
            onClick={handleLogout}
            exact
            to="/login"
            activeClassName="active"
            className="navigation-links"
          >
            {!loading && 'Logout '}
            <i
              className={loading ? 'fa fa-spinner fa-spin' : 'fa fa-unlock'}
            ></i>
          </NavLink>
        )}
      </li>
    </ul>
  )
}

export default NavLinks
