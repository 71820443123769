import React from 'react'

const CarSpecifications = ({ details }) => {
    const datePublished = details?.published_at?.substring(0, 10)
    const timePublished = details?.published_at?.substring(11, 16)
    const capitalizeFirstLetter = (string) => {
        return string?.charAt(0)?.toUpperCase() + string?.slice(1);
    }
    const ReactMarkdown = require('react-markdown')
    return (
        <div className="spec-row" id="summarySpecs">
            <h2>Specifications</h2>
            <div className="card-body">
                <ul className="list-group list-group-flush">
                    <li className="list-group-item">Reference Number : <span className="float-right">{details?.referenceNumber?.toUpperCase()}</span></li>
                    <li className="list-group-item">Chasis Number : <span className="float-right">{details?.chasisNumber?.toUpperCase()}</span></li>
                    <li className="list-group-item">Engine Number : <span className="float-right">{details?.engineNumber?.toUpperCase()}</span></li>
                    {details?.regNumber && 
                    <li className="list-group-item">Registration Number :  <span className="float-right">{details?.regNumber?.toUpperCase()}</span></li>}
                    <li className="list-group-item">Body : <span className="float-right">{capitalizeFirstLetter(details?.bodyType)}</span></li>
                    {details?.color?.name && <li className="list-group-item">Color :  <span className="float-right">{capitalizeFirstLetter(details?.color?.name)}</span></li>}
                    <li className="list-group-item">Doors : <span className="float-right">{details?.doors}</span></li>
                    <li className="list-group-item">Mileage : <span className="float-right">{details?.mileage} km</span></li>
                    <li className="list-group-item">Engine Displacement :  <span className="float-right">{details?.displacement} cc</span></li>
                    <li className="list-group-item">Fuel<span className="float-right">{capitalizeFirstLetter(details?.fuelType)}</span></li>
                    <li className="list-group-item">Passengers :<span className="float-right"> {details?.passengers} </span></li>
                    <li className="list-group-item">Transmission :  <span className="float-right">{capitalizeFirstLetter(details?.transmissionType)}</span></li>
                    <li className="list-group-item">Year of Make : <span className="float-right">{details?.year}</span></li>
                    <li className="list-group-item">Description : <span className="float-right"><ReactMarkdown children={details?.description} /></span></li>
                </ul>
            </div>
            <div className="card-footer text-muted">
                Last Updated : <b>
                    {datePublished}
                    <span className="float-right">{timePublished}</span>
                </b>
            </div>
        </div>
    )
}

export default CarSpecifications
