import React, { useContext, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { CarContext } from '../../../../../Contexts/CarContext'
import CardHeader from './CardHeader'

const CarSelected = () => {
  const { car } = useContext(CarContext)
  const [show, setShow] = useState(false)
  const { items = [] } = car
  const capitalizeFirstLetter = (string) => {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1)
  }
  const ReactMarkdown = require('react-markdown')
  return (
    <div className="card mb-3">
      <CardHeader
        reviewStageIcon={!show ? 'chevron-up' : 'chevron-down'}
        reviewStageTitle="Selected Car Details"
        show={show}
        setShow={setShow}
      />
      {show && (
        <div className="marginIsAMust">
          {items.length > 0 &&
            items.map((item) => (
              <div className="row g-0" key={item?.id}>
                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <NavLink to={'/cars/' + item?.id}>
                    <img
                      className="card-img-top"
                      src={item?.photos[0]?.url}
                      alt={`${item?.version}`}
                    />
                  </NavLink>
                </div>
                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="card-header">
                    {item?.specs ? (
                      <h5>
                        {`${capitalizeFirstLetter(
                          item?.manufacturer?.name,
                        )} ${capitalizeFirstLetter(item?.specs)}`}
                      </h5>
                    ) : (
                      <h5>
                        {`${capitalizeFirstLetter(
                          item?.manufacturer?.name,
                        )} ${capitalizeFirstLetter(item?.version?.name)}`}
                      </h5>
                    )}
                  </div>
                  <div className="card-body">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        Reference Number :{' '}
                        <span className="float-right">
                          {item?.referenceNumber?.toUpperCase()}
                        </span>
                      </li>
                      <li className="list-group-item">
                        Chasis Number :{' '}
                        <span className="float-right">
                          {item?.chasisNumber?.toUpperCase()}
                        </span>
                      </li>
                      <li className="list-group-item">
                        Engine Number :{' '}
                        <span className="float-right">
                          {item?.engineNumber?.toUpperCase()}
                        </span>
                      </li>
                      {item?.price && (
                        <li className="list-group-item">
                          Registration Number :{' '}
                          <span className="float-right">
                            US ${item?.price?.toFixed(2)}
                          </span>
                        </li>
                      )}
                      {item?.regNumber && (
                        <li className="list-group-item">
                          Registration Number :{' '}
                          <span className="float-right">
                            {item?.regNumber?.toUpperCase()}
                          </span>
                        </li>
                      )}
                      <li className="list-group-item">
                        Body :{' '}
                        <span className="float-right">
                          {capitalizeFirstLetter(item?.bodyType)}
                        </span>
                      </li>
                      {item?.color?.name && (
                        <li className="list-group-item">
                          Color :{' '}
                          <span className="float-right">
                            {capitalizeFirstLetter(item?.color?.name)}
                          </span>
                        </li>
                      )}
                      <li className="list-group-item">
                        Doors :{' '}
                        <span className="float-right">{item?.doors}</span>
                      </li>
                      <li className="list-group-item">
                        Mileage :{' '}
                        <span className="float-right">{item?.mileage} km</span>
                      </li>
                      <li className="list-group-item">
                        Engine Displacement :{' '}
                        <span className="float-right">
                          {item?.displacement} cc
                        </span>
                      </li>
                      <li className="list-group-item">
                        Fuel
                        <span className="float-right">
                          {capitalizeFirstLetter(item?.fuelType)}
                        </span>
                      </li>
                      <li className="list-group-item">
                        Passengers :
                        <span className="float-right">
                          {' '}
                          {item?.passengers}{' '}
                        </span>
                      </li>
                      <li className="list-group-item">
                        Transmission :{' '}
                        <span className="float-right">
                          {capitalizeFirstLetter(item?.transmissionType)}
                        </span>
                      </li>
                      <li className="list-group-item">
                        Year of Make :{' '}
                        <span className="float-right">{item?.year}</span>
                      </li>
                      <li className="list-group-item">
                        Description :{' '}
                        <span className="float-right">
                          <ReactMarkdown children={item?.description} />
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="card-footer text-muted">
                    Last Updated :{' '}
                    <b>
                      {item?.published_at?.substring(0, 10)}
                      <span className="float-right">
                        {item?.published_at?.substring(11, 16)}
                      </span>
                    </b>
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  )
}

export default CarSelected
