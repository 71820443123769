import React from 'react'
import Dashboard from '../Components/Dashboard/Dashboard'

const DashboardPage = () => {
    return (
        <Dashboard />
    )
}

export default DashboardPage
