import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { searchCars } from '../../../Services/api'
import AboutCar from './AboutCar'
import CarImages from './Components/CarImages'
import CarSpecifications from './Components/CarSpecifications'
import Preloader from '../../Preloader/Preloader'
import AddCar from '../Buttons/AddCar'
import EnquireCar from '../Buttons/EnquireCar'

const CarDetails = () => {
  const { referenceNumber } = useParams()
  const [carDetails, setCarDetails] = useState([])
  const [pictures, setPictures] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let mounted = false
    const getCarDetails = async () => {
      try {
        setLoading(true)
        const data = await searchCars(`/${referenceNumber}`)
        setCarDetails(data)
        setPictures(data?.photos)
        setLoading(false)
      } catch (error) {
        console.error(error)
      }
    }
    getCarDetails()
    return () => {
      mounted = true
    }
  }, [referenceNumber])
  return (
    <div className="col-main col-xl-9 col-lg-12 col-md-12 col-sm-12 col-xs-12 product-grid">
      <div className="logo-brand">
        <div className="brand-title">
          <h2 className="text-center">
            {loading ? (
              <i className="fa fa-spinner fa-spin"></i>
            ) : (
              `${'REF # : ' + carDetails?.referenceNumber}`
            )}
          </h2>
        </div>
      </div>
      {!loading ? (
        <div className="pro-coloumn container">
          <div className="row">
            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3">
              <div className="product-full">
                <CarImages photos={pictures} />
              </div>
              {/* end: more-images */}
              {carDetails.price ? (
                <AddCar product={carDetails} />
              ) : (
                <EnquireCar product={carDetails} />
              )}
            </div>
            <div className="product-shop col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <AboutCar details={carDetails} />
              <CarSpecifications details={carDetails} />
            </div>
          </div>
        </div>
      ) : (
        <div className="col-xl-12 col-lg-12">
          <Preloader />
        </div>
      )}
    </div>
  )
}

export default CarDetails
