import React, { useEffect, useState } from 'react'
import { fetchCars } from '../../Services/api'
import CarsContainer from './Components/CarsContainer'
import NoCars from './Components/NoCars'

const Cars = () => {
  // define cars, loading state
  const [cars, setCars] = useState([])
  const [loading, setLoading] = useState(false)

  // getCars function in useEffect runs once
  useEffect(() => {
    const getCars = async () => {
      try {
        setLoading(true)
        const data = await fetchCars(`_sort=published_at:desc`)
        setCars(data)
        setLoading(false)
      } catch (error) {
        console.error(error)
      }
    }
    getCars()
    return () => {
      setCars([])
    }
  }, [])
  return (
    <React.Fragment>
      {cars.length > 0 ? (
        <CarsContainer cars={cars} loading={loading} />
      ) : (
        <NoCars loading={loading} />
      )}
    </React.Fragment>
  )
}

export default Cars
